<div class="example-form" fxLayout="column" fxLayoutAlign="start stretch" style="padding-bottom: 40px;">
  <mat-card class="cards-transations" *ngIf="!bandMobile">
    <div class="col card-header">
      <div class="center texto">
        <h6><strong>{{idiomas.Recargas | uppercase}}</strong></h6>
      </div>
    </div>
    <div style="padding-left: 16px; padding-right: 16px; padding-top: 16px;" autocomplete="off" class="form-row">
      <div class="col-12">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.Referencia}}</mat-label>
          <mat-select [formControl]="referenceFormControl" (selectionChange)="onSelectedReference($event.value)">
            <mat-option *ngFor="let reference of references" [value]="reference.CSERVICIO">
              {{reference.DESCRIPCION}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="referenceFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="referenceFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumeros}} </strong>
          </mat-error>
        </mat-form-field>
      </div>
      <!--<div class="col-6" *ngIf="selectedReference">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>{{idiomas.CodigoServicio}}</mat-label>
          <input matInput readonly [disabled]="bandDisableForm" type="text" autocomplete="off"
            value={{selectedReference.DESCRIPCION}}>
        </mat-form-field>
      </div>-->
      <div class="col-6" [class.col-12]="bandMobile" *ngIf="selectedReference">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.NumCelular}}</mat-label>
          <input matInput type="text" autocomplete="off" [formControl]="suministroForm">
          <mat-error *ngIf="suministroForm.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6" [class.col-12]="bandMobile" *ngIf="selectedReference && (amountValuesArray == null)">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.MontoRecarga}}</mat-label>
          <mat-select *ngIf="selectedReference.CSERVICIO === '19'" [formControl]="rechargeFormControl" (selectionChange)="onSelectionChange($event)" [(value)]="selectedValue">
            <mat-option *ngFor="let amount of amountsClaro" [value]="amount.value">
              $ {{ amount.value | cantidadMonetaria }}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <input *ngIf="selectedReference.CSERVICIO !== '19'" matInput (focus)="caretInputAmount($event)"
            (blur)="rechargeFormControl.setValue(validators.FormatNumberZeroValidation($event.target.value))"
            (keypress)="amountKeyInputControl($event)" type="text" autocomplete="off" inputmode="numeric"
            [formControl]="rechargeFormControl">
          <mat-error *ngIf="rechargeFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="rechargeFormControl.hasError('pattern')
        && !rechargeFormControl.hasError('required')">
            <strong>{{idiomas.msjSoloNumeros}} </strong>
          </mat-error>
          <mat-error *ngIf="rechargeFormControl.hasError('minAmount')
          && !rechargeFormControl.hasError('required')
          && !rechargeFormControl.hasError('pattern')">
            <strong>{{idiomas.montoMinimoRecargas}}{{minValueAmount}}</strong>
          </mat-error>
          <mat-error *ngIf="rechargeFormControl.hasError('maxAmount')
        && !rechargeFormControl.hasError('required')
        && !rechargeFormControl.hasError('pattern')
        && !rechargeFormControl.hasError('minAmount')">
            <strong>{{idiomas.montoMaximoRecargas}}{{maxValueAmount}}</strong>
          </mat-error>
          <mat-error *ngIf="rechargeFormControl.hasError('noDecimalAmount')
        && !rechargeFormControl.hasError('required')
        && !rechargeFormControl.hasError('pattern')
        && !rechargeFormControl.hasError('minAmount')
        && !rechargeFormControl.hasError('maxAmount')">
            <strong>{{idiomas.montoEnteroRecargas}}</strong>
          </mat-error>
          <mat-error *ngIf="rechargeFormControl.hasError('nMultipleAmount')
        && !rechargeFormControl.hasError('required')
        && !rechargeFormControl.hasError('pattern')
        && !rechargeFormControl.hasError('minAmount')
        && !rechargeFormControl.hasError('maxAmount')
        && !rechargeFormControl.hasError('noDecimalAmount')">
            <strong>{{idiomas.montoMinimoRecargas}}{{minValueAmount}}
              {{idiomas.montoIntervaloRecargas}}{{stepValueAmount}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6" [class.col-12]="bandMobile" *ngIf="selectedReference && (amountValuesArray != null)">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.MontoRecarga}}</mat-label>
          <mat-select [formControl]="rechargeFormControl">
            <mat-option *ngFor="let amountValue of amountValuesArray" [value]="amountValue.value">
              {{amountValue.viewValue}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="rechargeFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6" [class.col-12]="bandMobile" *ngIf="selectedReference">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
          <mat-select [formControl]="accountFormControl" (selectionChange)="onSelectedAccount($event.value)"
            (openedChange)="funcionRetornarCuentasOwn($event)">
            <mat-option *ngIf="!intentoCuentasOwn" disabled>
              <div style="margin: 10px;">
                <mat-progress-bar mode="indeterminate">
                </mat-progress-bar>
              </div>
            </mat-option>
            <mat-option *ngFor="let account of accounts" [value]="account.CCUENTA">
              {{account.CCUENTA | ocultarCuentaPosTrans}} - {{account.DESCRIPCION}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="accountFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12" *ngIf="selectedReference">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.Descripcion}}</mat-label>
          <input matInput type="text" autocomplete="off" [formControl]="descriptionFormControl"
            oninput="this.value = this.value.toUpperCase();">
          <mat-error *ngIf="descriptionFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="descriptionFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="selectedReference && internet.internet" (keyup.enter)="makeRecharge()"
        style="padding-left: 5px !important;">

        <mat-form-field class="col-2 token" appearance="outline" [class.col-12]="bandMobile" *ngIf="tokenRequested">
          <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
          <input id="soliToken" matInput type="password" autocomplete="off" [formControl]="tokenFormControl"
            maxlength="6" inputmode="numeric" name="token" appBlockCopyPaste>
          <mat-error *ngIf="tokenFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="tokenFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumeros}}</strong>
          </mat-error>
          <mat-error *ngIf="tokenFormControl.hasError('minlength')">
            <strong>{{idiomas.msjOtpminmax}}</strong>
          </mat-error>
          <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
            <strong>{{idiomas.msjOtpminmax}}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2 token" appearance="outline" [class.col-12]="bandMobile"
          *ngIf="internet.internet && !tokenRequested">
          <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
          <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
        </mat-form-field>
        <div class="left col-2 token-button" *ngIf="internet.internet">
          <button
            [disabled]="(!suministroForm.valid || !rechargeFormControl.valid || !selectedAccount || !selectedReference || !descriptionFormControl.valid) || (enableCronoIn)"
            mat-raised-button (click)="solicitarToken()" color="primary" class="buttonToken">
            <mat-icon>security</mat-icon>
            {{idiomas.SolicitarToken}}
          </button>
        </div>
        <div *ngIf="mostrarCron" class="col" style="padding-left: 25px !important;">
          <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
        </div>
      </div>
    </div>
    <div *ngIf="intento" style="padding-left: 16px; padding-right: 16px;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div id="floatCont" class="form-row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
      <div class="col-6">
        <button mat-raised-button (click)="makeRecharge()" title={{idiomas.Aceptar}} *ngIf="
              (!internet.internet && accountFormControl.valid && referenceFormControl.valid && descriptionFormControl.valid && rechargeFormControl.valid)
              ||
              (internet.internet && accountFormControl.valid && referenceFormControl.valid && descriptionFormControl.valid && rechargeFormControl.valid && tokenRequested && tokenFormControl.valid)
              " color="primary" class="buttonSubmit" [disabled]="disableButton">
          <mat-icon>download_done</mat-icon>
          {{idiomas.Aceptar}}
        </button>
      </div>
      <div class="col-6" align="end" style="text-align: end;">
        <button mat-raised-button
          *ngIf="selectedAccount || selectedReference || descriptionFormControl.value || rechargeFormControl.value"
          (click)="clearScreenWithDialog()" title={{idiomas.Limpiar}} color="warn" class="buttonClean">
          <mat-icon> clear_all</mat-icon>
          {{idiomas.Limpiar}}
        </button>
      </div>
    </div>
  </mat-card>
  <div *ngIf="bandMobile">
    <div class="col card-header">
      <div class="center texto">
        <h6><strong>{{idiomas.Recargas | uppercase}}</strong></h6>
      </div>
    </div>
    <div style="padding-left: 16px; padding-right: 16px; padding-top: 16px;" autocomplete="off" class="form-row">
      <div class="col-12">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.Referencia}}</mat-label>
          <mat-select [formControl]="referenceFormControl" (selectionChange)="onSelectedReference($event.value)">
            <mat-option *ngFor="let reference of references" [value]="reference.CSERVICIO">
              {{reference.DESCRIPCION}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="referenceFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="referenceFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumeros}} </strong>
          </mat-error>
        </mat-form-field>
      </div>
      <!--<div class="col-6" *ngIf="selectedReference">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>{{idiomas.CodigoServicio}}</mat-label>
          <input matInput readonly [disabled]="bandDisableForm" type="text" autocomplete="off"
            value={{selectedReference.DESCRIPCION}}>
        </mat-form-field>
      </div>-->
      <div class="col-6" [class.col-12]="bandMobile" *ngIf="selectedReference">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.NumCelular}}</mat-label>
          <input matInput type="text" autocomplete="off" [formControl]="suministroForm" inputmode="numeric">
          <mat-error *ngIf="suministroForm.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6" [class.col-12]="bandMobile" *ngIf="selectedReference && (amountValuesArray == null)">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.MontoRecarga}}</mat-label>
          <mat-select *ngIf="selectedReference.CSERVICIO === '19'" [formControl]="rechargeFormControl" (selectionChange)="onSelectionChange($event)" [(value)]="selectedValue">
            <mat-option *ngFor="let amount of amountsClaro" [value]="amount.value">
              $ {{ amount.value | cantidadMonetaria }}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <input *ngIf="selectedReference.CSERVICIO !== '19'" matInput (focus)="caretInputAmount($event)"
            (blur)="rechargeFormControl.setValue(validators.FormatNumberZeroValidation($event.target.value))"
            (keypress)="amountKeyInputControl($event)" type="text" autocomplete="off" inputmode="numeric"
            [formControl]="rechargeFormControl">
          <mat-error *ngIf="rechargeFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="rechargeFormControl.hasError('pattern')
        && !rechargeFormControl.hasError('required')">
            <strong>{{idiomas.msjSoloNumeros}} </strong>
          </mat-error>
          <mat-error *ngIf="rechargeFormControl.hasError('minAmount')
          && !rechargeFormControl.hasError('required')
          && !rechargeFormControl.hasError('pattern')">
            <strong>{{idiomas.montoMinimoRecargas}}{{minValueAmount}}</strong>
          </mat-error>
          <mat-error *ngIf="rechargeFormControl.hasError('maxAmount')
        && !rechargeFormControl.hasError('required')
        && !rechargeFormControl.hasError('pattern')
        && !rechargeFormControl.hasError('minAmount')">
            <strong>{{idiomas.montoMaximoRecargas}}{{maxValueAmount}}</strong>
          </mat-error>
          <mat-error *ngIf="rechargeFormControl.hasError('noDecimalAmount')
        && !rechargeFormControl.hasError('required')
        && !rechargeFormControl.hasError('pattern')
        && !rechargeFormControl.hasError('minAmount')
        && !rechargeFormControl.hasError('maxAmount')">
            <strong>{{idiomas.montoEnteroRecargas}}</strong>
          </mat-error>
          <mat-error *ngIf="rechargeFormControl.hasError('nMultipleAmount')
        && !rechargeFormControl.hasError('required')
        && !rechargeFormControl.hasError('pattern')
        && !rechargeFormControl.hasError('minAmount')
        && !rechargeFormControl.hasError('maxAmount')
        && !rechargeFormControl.hasError('noDecimalAmount')">
            <strong>{{idiomas.montoMinimoRecargas}}{{minValueAmount}}
              {{idiomas.montoIntervaloRecargas}}{{stepValueAmount}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6" [class.col-12]="bandMobile" *ngIf="selectedReference && (amountValuesArray != null)">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.MontoRecarga}}</mat-label>
          <mat-select [formControl]="rechargeFormControl">
            <mat-option *ngFor="let amountValue of amountValuesArray" [value]="amountValue.value">
              {{amountValue.viewValue}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="rechargeFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6" [class.col-12]="bandMobile" *ngIf="selectedReference">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
          <mat-select [formControl]="accountFormControl" (selectionChange)="onSelectedAccount($event.value)"
            (openedChange)="funcionRetornarCuentasOwn($event)">
            <mat-option *ngIf="!intentoCuentasOwn" disabled>
              <div style="margin: 10px;">
                <mat-progress-bar mode="indeterminate">
                </mat-progress-bar>
              </div>
            </mat-option>
            <mat-option *ngFor="let account of accounts" [value]="account.CCUENTA">
              {{account.CCUENTA | ocultarCuentaPosTrans}} - {{account.DESCRIPCION}}
              <mat-divider style="border-top-width: 2px;"></mat-divider>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="accountFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12" *ngIf="selectedReference">
        <mat-form-field appearance="outline" fxFlexFill>
          <mat-label>{{idiomas.Descripcion}}</mat-label>
          <input matInput type="text" autocomplete="off" [formControl]="descriptionFormControl"
            oninput="this.value = this.value.toUpperCase();">
          <mat-error *ngIf="descriptionFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="descriptionFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="selectedReference && internet.internet" (keyup.enter)="makeRecharge()"
        style="padding-left: 5px !important;">
        <div class="left col-6 token-button" *ngIf="internet.internet" style="padding-bottom: 10px !important;">
          <button style="margin-top: 0 !important;"
            [disabled]="(!suministroForm.valid || !rechargeFormControl.valid || !selectedAccount || !selectedReference || !descriptionFormControl.valid) || (enableCronoIn)"
            mat-raised-button (click)="solicitarToken()" color="primary" class="buttonToken">
            <mat-icon>security</mat-icon>
            {{idiomas.SolicitarToken}}
          </button>
        </div>
        <div *ngIf="mostrarCron" class="col-6" style="padding-left: 15px !important;">
          <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
        </div>
        <mat-form-field class="col-2 token" appearance="outline" [class.col-12]="bandMobile" *ngIf="tokenRequested">
          <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
          <input id="soliToken" matInput type="password" autocomplete="off" [formControl]="tokenFormControl"
            maxlength="6" inputmode="numeric" name="token" appBlockCopyPaste>
          <mat-error *ngIf="tokenFormControl.hasError('required')">
            <strong>{{idiomas.ValidarCampo}}</strong>
          </mat-error>
          <mat-error *ngIf="tokenFormControl.hasError('pattern')">
            <strong>{{idiomas.msjSoloNumeros}}</strong>
          </mat-error>
          <mat-error *ngIf="tokenFormControl.hasError('minlength')">
            <strong>{{idiomas.msjOtpminmax}}</strong>
          </mat-error>
          <mat-error *ngIf="tokenFormControl.hasError('maxlength')">
            <strong>{{idiomas.msjOtpminmax}}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2 token" appearance="outline" [class.col-12]="bandMobile"
          *ngIf="internet.internet && !tokenRequested">
          <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
          <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="intento" style="padding-left: 16px; padding-right: 16px;">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div id="floatCont" class="form-row" style="padding-left: 16px; padding-right: 16px; padding-bottom: 16px;">
      <div class="col-6">
        <button mat-raised-button (click)="makeRecharge()" title={{idiomas.Aceptar}} *ngIf="
              (!internet.internet && accountFormControl.valid && referenceFormControl.valid && descriptionFormControl.valid && rechargeFormControl.valid)
              ||
              (internet.internet && accountFormControl.valid && referenceFormControl.valid && descriptionFormControl.valid && rechargeFormControl.valid && tokenRequested && tokenFormControl.valid)
              " color="primary" class="buttonSubmit" [disabled]="disableButton">
          <mat-icon>download_done</mat-icon>
          {{idiomas.Aceptar}}
        </button>
      </div>
      <div class="col-6" align="end" style="text-align: end;">
        <button mat-raised-button
          *ngIf="selectedAccount || selectedReference || descriptionFormControl.value || rechargeFormControl.value"
          (click)="clearScreenWithDialog()" title={{idiomas.Limpiar}} color="warn" class="buttonClean">
          <mat-icon> clear_all</mat-icon>
          {{idiomas.Limpiar}}
        </button>
      </div>
    </div>
  </div>
</div>