<div class="textura" [class.mediano]=normal [class.mediano]=mediano [class.grande]=grande>
    <h2 style="display: flex; justify-content: center; align-items: center;" mat-dialog-title>{{idioma.CodigoQR}}</h2>

    <mat-dialog-content>
        <div style="text-align: center;" *ngIf="isScanQR">
            <p>{{idioma.MsgScanearCodigoTran}}</p>
        </div>
        <div style="display: flex; justify-content: center; align-items: center;" *ngIf="isScanQR">
            <qrcode [qrdata]="data" [imageSrc]="'assets/resources/mipmap-hdpi/imagen-qr.png'" [imageHeight]="60"
                [imageWidth]="60" [width]="300" [margin]="5" [errorCorrectionLevel]="'M'">
            </qrcode>
        </div>
        <div style="text-align: center;" *ngIf="!isScanQR">
            <p style="text-align: center;">{{idioma.LeerQR}}</p>
            <div style="text-align: center;" *ngIf="!isScanQR">
                <zxing-scanner #scanner (scanSuccess)="scanSuccessHandler($event)">
                </zxing-scanner>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions style="padding-top: 0px">
        <div style="width: 100% !important; text-align: right;">
            <button mat-raised-button class="buttonToken" (click)="close()">
                <mat-icon>done</mat-icon>{{idioma.Aceptar}}
            </button>
        </div>
    </mat-dialog-actions>
</div>