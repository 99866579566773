<div *ngIf="!bandNewBeneficiary" class="example-form" style="padding-top: 2% !important;" fxLayout="column"
    fxLayoutAlign="start stretch">
    <mat-card class="cards-transations" *ngIf="!bandMobile">
        <div class="col card-header">
            <div class="center texto">
                <h6><strong>{{idiomas.TransferenciasTerceros | uppercase}}</strong></h6>
            </div>
        </div>
        <div style="padding: 16px;">
            <div *ngIf="!intentoReenviar">
                <strong>
                    <mat-label>{{idiomas.DatosTransferencia}}</mat-label>
                </strong>
                <div autocomplete="off" class="form-row" style="padding-top: 10px;">
                    <div class="col-6" style="min-width: 225px">
                        <mat-form-field appearance="outline" fxFlexFill>
                            <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
                            <mat-select [formControl]="cuentaDesdeSelected" (selectionChange)="seleccionarCuentaDesde()"
                                [compareWith]="compareJsonPerValue" (openedChange)="funcionRetornarCuentasOwn($event)">
                                <mat-option *ngIf="!intentoCuentasOwn" disabled>
                                    <div style="margin: 10px;">
                                        <mat-progress-bar mode="indeterminate">
                                        </mat-progress-bar>
                                    </div>
                                </mat-option>
                                <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta"
                                    (onSelectionChange)="selectedOptionOrigen(cuenta)">
                                    {{cuenta.viewValue | ocultarCuentaPosTrans}} - {{cuenta.descProducto}}
                                    <mat-divider style="border-top-width: 2px;"></mat-divider>
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="cuentaDesdeSelected.hasError('required')">
                                <strong>{{idiomas.ValidarCampo}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="!bandMobile" id="montoTransfer" class="col-4" style="min-width: 225px">
                        <mat-form-field appearance="outline" floatLabel="always" fxFlexFill>
                            <mat-label>{{idiomas.MontoTransferencia}}</mat-label>
                            <input class="numeros" matInput (focus)="caretInputAmount($event)"
                                (blur)="montoTransfer.setValue(this.validators.FormatNumberZeroValidation($event.target.value))"
                                (keypress)="amountKeyInputControl($event)" type="text" autocomplete="off"
                                [formControl]="montoTransfer" name="montoTransfer" maxlength="10" inputmode="decimal"
                                currencyInput placeholder="$0.00">
                            <mat-error *ngIf="montoTransfer.hasError('required')">
                                <strong>{{idiomas.ValidarCampo}}</strong>
                            </mat-error>
                            <mat-error *ngIf="montoTransfer.hasError('pattern')">
                                <strong>{{idiomas.msjSoloNumeros}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div autocomplete="off" class="form-row">
                    <div class="col-6" style="min-width: 225px">
                        <mat-form-field appearance="outline" fxFlexFill>
                            <mat-label>{{idiomas.HaciaCuenta}}</mat-label>
                            <mat-select [formControl]="cuentaHaciaSelected" (selectionChange)="seleccionarCuentaHacia()"
                                [compareWith]="compareJsonPerValue" (openedChange)="funcionRetornarCuentasBene($event)">
                                <mat-option *ngIf="!intentoCuentasBene" disabled>
                                    <div style="margin: 10px;">
                                        <mat-progress-bar mode="indeterminate">
                                        </mat-progress-bar>
                                    </div>
                                </mat-option>
                                <input matInput *ngIf="intentoCuentasBene" autocomplete="off"
                                    (keyup)="applyFilter($event.target.value.toUpperCase())"
                                    (keydown)="$event.stopPropagation()"
                                    placeholder="{{idiomas.FiltrarResultados | uppercase }}"
                                    style="text-transform: uppercase;padding: 2px;height: 35px;border-bottom-style: groove;">
                                <mat-option *ngFor="let cuenta of cuentasBeneficiarios" [value]="cuenta"
                                    (onSelectionChange)="selectedOptionDestino(cuenta)">
                                    {{cuenta.value | ocultarCuentaPosTrans}} - {{cuenta.descProducto}} -
                                    {{cuenta.nombre}}
                                    <mat-divider style="border-top-width: 2px;"></mat-divider>
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="cuentaHaciaSelected.hasError('required')">
                                <strong>{{idiomas.ValidarCampo}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div autocomplete="off" class="form-row">
                    <div class="col-6" style="min-width: 225px">
                        <mat-form-field appearance="outline" fxFlexFill>
                            <mat-label>{{idiomas.Referencia}}</mat-label>
                            <div>
                                <input matInput type="text" autocomplete="off" style="text-transform: uppercase;"
                                    [formControl]="referencia" name="referencia">
                            </div>
                            <mat-error *ngIf="referencia.hasError('required')">
                                <strong>{{idiomas.ValidarCampo}}</strong>
                            </mat-error>
                            <mat-error *ngIf="referencia.hasError('pattern')">
                                <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="!pinValidation && internet.internet" autocomplete="off" class="form-row"
                    (keyup.enter)="obtenerComision('1')">
                    <mat-form-field class="col-2 token" *ngIf="tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input id="soliToken" matInput type="text" autocomplete="off"
                            onkeydown="this.setAttribute('type','password')" [formControl]="token" maxlength="6"
                            inputmode="numeric" appBlockCopyPaste>
                        <mat-error *ngIf="token.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="token.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="token.hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="token.hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!tokenSolicitado" appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                    <div *ngIf="internet.internet" class="left col-2 token-button">
                        <button
                            [disabled]="(!cuentaDesdeSelected.valid) || (!cuentaHaciaSelected.value) || (!montoTransfer.valid) || (!referencia.valid) || (enableCronoIn)"
                            mat-raised-button (click)="solicitarToken()" color="primary" class="buttonToken">
                            <mat-icon>security</mat-icon>
                            {{idiomas.SolicitarToken}}
                        </button>
                    </div>
                    <div *ngIf="mostrarCron" class="col" style="padding-left: 25px !important;">
                        <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                </div>
            </div>
            <div *ngIf=intento style="padding-top:  10px !important; padding-bottom: 10px !important;">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="form-row" *ngIf="!intentoReenviar">
                <div id="floatCont" class="col-6">
                    <button mat-raised-button
                        *ngIf="(pinValid || !internet.internet) && cuentaDesdeSelected.valid && cuentaHaciaSelected.value && montoTransfer.valid && referencia.valid"
                        (click)="obtenerComision('1')" title={{idiomas.Aceptar}} color="primary"
                        class="buttonSubmit" [disabled]="disableButton">
                        <mat-icon>download_done</mat-icon>
                        {{idiomas.transfererir}}
                    </button>
                    <button mat-raised-button
                        *ngIf="((token.valid && tokenSolicitado) || !internet.internet) && cuentaDesdeSelected.valid && cuentaHaciaSelected.value && montoTransfer.valid && referencia.valid"
                        (click)="obtenerComision('0')" title={{idiomas.Aceptar}} color="primary"
                        class="buttonSubmit" [disabled]="disableButton">
                        <mat-icon>download_done</mat-icon>
                        {{idiomas.transfererir}}
                    </button>
                    <button *ngIf="pinValidation && !pinValid"
                        [disabled]="cuentaDesdeSelected.invalid || cuentaHaciaSelected.invalid || montoTransfer.invalid || referencia.invalid || disableButton"
                        mat-raised-button (click)="solicitarAutorizacion()" title={{idiomas.SolicitarAutorizacion}}
                        color="primary" class="buttonSubmit">
                        <mat-icon>security</mat-icon>
                        {{idiomas.SolicitarAutorizacion}}
                    </button>
                </div>
                <div id="floatCont" align="end" style="text-align: end;" class="col-6">
                    <button mat-raised-button (click)="close()" title={{idiomas.Limpiar}} color="warn"
                        class="buttonClean">
                        <mat-icon> clear_all</mat-icon>
                        {{idiomas.Limpiar}}
                    </button>
                </div>
            </div>
        </div>
    </mat-card>
    <div *ngIf="bandMobile">
        <div style="padding: 12px;">
            <div *ngIf="!intentoReenviar">
                <div autocomplete="off" class="form-row">
                    <strong class="col-10">
                        <mat-label>{{idiomas.TransferenciasTerceros | uppercase}}</mat-label>
                    </strong>
                    <div class="col-2" style="text-align: end;">
                        <mat-icon (click)="close()"> delete_sweep</mat-icon>
                    </div>
                    <div class="col-6" [class.col-12]="bandMobile" style="min-width: 225px">
                        <mat-form-field appearance="outline" fxFlexFill>
                            <mat-label>{{idiomas.DesdeCuenta}}</mat-label>
                            <mat-select class="fit-content"
                                style="min-height: 40px !important; font-size: 1.1em !important;"
                                [formControl]="cuentaDesdeSelected" (selectionChange)="seleccionarCuentaDesde()"
                                [compareWith]="compareJsonPerValue" (openedChange)="funcionRetornarCuentasOwn($event)">
                                <mat-option *ngIf="!intentoCuentasOwn" disabled>
                                    <div style="margin: 10px;">
                                        <mat-progress-bar mode="indeterminate">
                                        </mat-progress-bar>
                                    </div>
                                </mat-option>
                                <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta"
                                    (onSelectionChange)="selectedOptionOrigen(cuenta)">
                                    {{cuenta.descProducto}} - {{cuenta.viewValue | ocultarCuentaPosTrans}}
                                    {{cuenta.SDisponible}}: ${{cuenta.saldo | cantidadMonetariaPosCon}}
                                    <mat-divider style="border-top-width: 2px;"></mat-divider>
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="cuentaDesdeSelected.hasError('required')">
                                <strong>{{idiomas.ValidarCampo}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6" [class.col-12]="bandMobile" style="min-width: 225px">
                        <mat-form-field class="withoutArrow" appearance="outline" fxFlexFill>
                            <mat-label>{{idiomas.HaciaCuenta}}</mat-label>
                            <mat-select class="fit-content"
                                style="min-height: 50px !important;font-size: 1.1em !important;"
                                [formControl]="cuentaHaciaSelected" (selectionChange)="seleccionarCuentaHacia()"
                                [compareWith]="compareJsonPerValue" (openedChange)="funcionRetornarCuentasBene($event)">
                                <mat-option *ngIf="!intentoCuentasBene" disabled>
                                    <div style="margin: 10px;">
                                        <mat-progress-bar mode="indeterminate">
                                        </mat-progress-bar>
                                    </div>
                                </mat-option>
                                <input matInput *ngIf="intentoCuentasBene" autocomplete="off"
                                    (keyup)="applyFilter($event.target.value.toUpperCase())"
                                    (keydown)="$event.stopPropagation()"
                                    placeholder="{{idiomas.FiltrarResultados | uppercase }}"
                                    style="text-transform: uppercase;padding: 2px;height: 35px;border-bottom-style: groove;">
                                <mat-option *ngFor="let cuenta of cuentasBeneficiarios" [value]="cuenta"
                                    (onSelectionChange)="selectedOptionDestino(cuenta)">
                                    {{cuenta.descProducto}} - {{cuenta.value | ocultarCuentaPosTrans}} -
                                    {{cuenta.nombre}}
                                    <mat-divider style="border-top-width: 2px;"></mat-divider>
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="cuentaHaciaSelected.hasError('required')">
                                <strong>{{idiomas.ValidarCampo}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div iclass="col-6" [class.col-12]="bandMobile" style="min-width: 225px">
                        <mat-form-field floatLabel="always" fxFlexFill>
                            <mat-label>{{idiomas.MontoTransferenciaT}}</mat-label>
                            <input style="font-size: 3.1em; text-align: center !important;" class="numeros Input"
                                matInput (focus)="caretInputAmount($event)"
                                (blur)="montoTransfer.setValue(this.validators.FormatNumberZeroValidation($event.target.value))"
                                (keypress)="amountKeyInputControl($event)" type="text" autocomplete="off"
                                [formControl]="montoTransfer" name="montoTransfer" maxlength="10" inputmode="decimal"
                                currencyInput placeholder="$0.00">
                            <mat-error *ngIf="montoTransfer.hasError('required')">
                                <strong>{{idiomas.ValidarCampo}}</strong>
                            </mat-error>
                            <mat-error *ngIf="montoTransfer.hasError('pattern')">
                                <strong>{{idiomas.msjSoloNumeros}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6" [class.col-12]="bandMobile" style="min-width: 225px">
                        <mat-form-field appearance="outline" fxFlexFill>
                            <mat-label>{{idiomas.Referencia}}</mat-label>
                            <div>
                                <input matInput type="text" autocomplete="off" style="text-transform: uppercase;"
                                    [formControl]="referencia" name="referencia">
                            </div>
                            <mat-error *ngIf="referencia.hasError('required')">
                                <strong>{{idiomas.ValidarCampo}}</strong>
                            </mat-error>
                            <mat-error *ngIf="referencia.hasError('pattern')">
                                <strong>{{idiomas.msjSoloNumerosLetras}} </strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="!pinValidation && internet.internet" autocomplete="off" class="form-row"
                    (keyup.enter)="obtenerComision('1')">
                    <div *ngIf="internet.internet" class="left col-6 token-button"
                        style="padding-bottom: 10px !important;">
                        <button style="margin-top: 0 !important;"
                            [disabled]="(!cuentaDesdeSelected.valid) || (!cuentaHaciaSelected.value) || (!montoTransfer.valid) || (!referencia.valid) || (enableCronoIn)"
                            mat-raised-button (click)="solicitarToken()" color="primary" class="buttonToken">
                            <mat-icon>security</mat-icon>
                            {{idiomas.SolicitarToken}}
                        </button>
                    </div>
                    <div *ngIf="mostrarCron" class="col-6" style="padding-left: 15px !important;">
                        <temporizador [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
                            [enableCronoIn]="enableCronoIn" (terminaCrono)="onTerminaCrono($event)"></temporizador>
                    </div>
                    <mat-form-field class="col-2 token" *ngIf="tokenSolicitado" [class.col-12]="bandMobile"
                        appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input id="soliToken" matInput type="text" autocomplete="off"
                            onkeydown="this.setAttribute('type','password')" [formControl]="token" maxlength="6"
                            inputmode="numeric" appBlockCopyPaste>
                        <mat-error *ngIf="token.hasError('required')">
                            <strong>{{idiomas.ValidarCampo}}</strong>
                        </mat-error>
                        <mat-error *ngIf="token.hasError('pattern')">
                            <strong>{{idiomas.msjSoloNumeros}}</strong>
                        </mat-error>
                        <mat-error *ngIf="token.hasError('minlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                        <mat-error *ngIf="token.hasError('maxlength')">
                            <strong>{{idiomas.msjOtpminmax}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2 token" *ngIf="!tokenSolicitado" [class.col-12]="bandMobile"
                        appearance="outline">
                        <mat-label>{{idiomas.TokenDeSeguridad}}</mat-label>
                        <input matInput type="password" autocomplete="off" disabled appBlockCopyPaste>
                    </mat-form-field>
                </div>
                <div *ngIf=intento style="padding-top:  10px !important; padding-bottom: 10px !important;">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <div  style="text-align: center; padding-top: 5px !important;">
                    <button *ngIf="token.valid" mat-raised-button (click)="obtenerComision('0')" title={{idiomas.TransferirMobMen}}
                        color="primary" class="buttonSubmitTransfer"
                        [disabled]="((token.valid && !tokenSolicitado) || pinValid || !internet.internet) || disableButton || cuentaDesdeSelected.invalid || cuentaHaciaSelected.invalid || montoTransfer.invalid || referencia.invalid"
                        style="font-size: 1.2em; height: 45px; min-width: 210px !important;">
                        {{idiomas.TransferirMobMen | uppercase}}
                    </button>

                    <button *ngIf="pinValidation" mat-raised-button (click)="obtenerComision('1')" title={{idiomas.TransferirMobMen}}
                        color="primary" class="buttonSubmitTransfer"
                        [disabled]="((token.valid && !tokenSolicitado) || pinValid || !internet.internet) || disableButton || cuentaDesdeSelected.invalid || cuentaHaciaSelected.invalid || montoTransfer.invalid || referencia.invalid"
                        style="font-size: 1.2em; height: 45px; min-width: 210px !important;">
                        {{idiomas.TransferirMobMen | uppercase}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="bandNewBeneficiary">
    <app-beneficiarios #beneficiarios [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas"
        [pendingTaskData]="regBenefJson" [bandTarjetaCredito]="1">
    </app-beneficiarios>
</div>
<div class="float-transacciones-mob" *ngIf="bandMobile && bandNewBeneficiary">
    <button class="btn btnGrafico" title={{idiomas.Regresar}} color="primary" (click)="retornar()">
        <mat-icon class="white">arrow_back</mat-icon>
    </button>
</div>
<div *ngIf="!bandMobile && bandNewBeneficiary" class="float-transacciones">
    <button mat-fab (click)="retornar()" title={{idiomas.Regresar}} class="float-right" color="primary">
        <mat-icon class="white">arrow_back</mat-icon>
    </button>
</div>