<!--Aqui empieza primer set de items para menu expandible-->
<div class="form-row" style="width: 100%;">
    <div class="col-4" style="margin-top: 7px; text-align: center !important;">
        <mat-icon *ngIf="tema == 'light-theme'" [ngStyle]="{'color':tabIndex === 1 ? '#fdc800' : '#0069b2'}"
            class="bottom-menu-icons" color="primary" (click)="move('CTransferencias');">compare_arrows</mat-icon>
        <mat-icon *ngIf="tema == 'night-theme'" [ngStyle]="{'color':tabIndex === 1 ? '#fdc800' : '#ffffff'}"
            class="bottom-menu-icons" color="primary" (click)="move('CTransferencias');">compare_arrows</mat-icon>
        <p *ngIf="tema == 'light-theme'" [ngStyle]="{'color':tabIndex === 1 ? '#fdc800' : '#0069b2'}"
            style="font-size: .6em;">
            {{idiomas.CTrans | uppercase}}</p>
        <p *ngIf="tema == 'night-theme'" [ngStyle]="{'color':tabIndex === 1 ? '#fdc800' : '#ffffff'}"
            style="font-size: .6em;">
            {{idiomas.CTrans | uppercase}}</p>
    </div>
    <div class="col-4" style="margin-top: 7px; text-align: center !important;">
        <mat-icon *ngIf="tema == 'light-theme'" [ngStyle]="{'color':tabIndex === 2 ? '#fdc800' : '#0069b2'}"
            class="bottom-menu-icons" color="primary" (click)="onTabClick(2);">credit_card</mat-icon>
        <mat-icon *ngIf="tema == 'night-theme'" [ngStyle]="{'color':tabIndex === 2 ? '#fdc800' : '#ffffff'}"
            class="bottom-menu-icons" color="primary" (click)="onTabClick(2);">credit_card</mat-icon>
        <p *ngIf="tema == 'light-theme'" [ngStyle]="{'color':tabIndex === 2 ? '#fdc800' : '#0069b2'}"
            style="font-size: .6em;">{{idiomas.Tarjeta |
            uppercase}}</p>
        <p *ngIf="tema == 'night-theme'" [ngStyle]="{'color':tabIndex === 2 ? '#fdc800' : '#ffffff'}"
            style="font-size: .6em;">{{idiomas.Tarjeta |
            uppercase}}</p>
    </div>
    <div class="col-4" style="margin-top: 7px; text-align: center !important;">
        <mat-icon *ngIf="tema == 'light-theme'" [ngStyle]="{'color':tabIndex === 3 ? '#fdc800' : '#0069b2'}"
            class="bottom-menu-icons" color="primary" (click)="onTabClick(3);">monetization_on</mat-icon>
        <mat-icon *ngIf="tema == 'night-theme'" [ngStyle]="{'color':tabIndex === 3 ? '#fdc800' : '#ffffff'}"
            class="bottom-menu-icons" color="primary" (click)="onTabClick(3);">monetization_on</mat-icon>
        <p *ngIf="tema == 'light-theme'" [ngStyle]="{'color':tabIndex === 3 ? '#fdc800' : '#0069b2'}"
            style="font-size: .6em;">{{idiomas.PlazoFijo |
            uppercase}}</p>
        <p *ngIf="tema == 'night-theme'" [ngStyle]="{'color':tabIndex === 3 ? '#fdc800' : '#ffffff'}"
            style="font-size: .6em;">{{idiomas.PlazoFijo |
            uppercase}}</p>
    </div>
</div>
<div>
    <mat-divider *ngIf="tabIndex === 1 || tabIndex === 2 || tabIndex === 3" style="padding-top: 4px; "></mat-divider>
    <!--Aqui empieza el menu expandible de TRANSFERENCIAS-->
    <div hidden class="form-row" *ngIf="tabIndex === 1">
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 1 ? '#fdc800' : '#0069b2'}" class="bottom-menu-icons"
                (click)="move('TransferenciasCuentasPropias');" color="primary">low_priority</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 1 ? '#fdc800' : '#0069b2'}" style="font-size: .6em;">
                {{idiomas.TransferenciasCuentasPropias | uppercase}}</p>
        </div>
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 1 ? '#fdc800' : '#0069b2'}" class="bottom-menu-icons"
                (click)="move('CTransferencias');" color="primary">chat_bubble_outline</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 1 ? '#fdc800' : '#0069b2'}" style="font-size: .6em;">
                {{idiomas.CTrans | uppercase}}</p>
        </div>
    </div>
    <!--Aqui termina el menu expandible de TRANSFERENCIAS-->

    <!--Aqui empieza el menu expandible de TRANSFERENCIAS TARJETAS-->
    <div class="form-row" *ngIf="tabIndex === 2">
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 2 ? '#fdc800' : '#0069b2'}" class="bottom-menu-icons"
                (click)="move('TarjetasCredito');" color="primary">credit_card</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 2 ? '#fdc800' : '#0069b2'}" style="font-size: .6em;">
                {{idiomas.TarjetasPc | uppercase}}</p>
        </div>
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 2 ? '#fdc800' : '#0069b2'}" class="bottom-menu-icons"
                (click)="move('CTransferenciasTarjeta');" color="primary">chat_bubble_outline</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 2 ? '#fdc800' : '#0069b2'}" style="font-size: .6em;">
                {{idiomas.CTarj | uppercase}}</p>
        </div>
    </div>
    <!--Aqui termina el menu expandible de TRANSFERENCIAS -->

    <!--Aqui empieza el menu expandible de INVERSIONES -->
    <div class="form-row" *ngIf="tabIndex === 3">
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 3 ? '#fdc800' : '#0069b2'}" class="bottom-menu-icons"
                (click)="move('plazoFijo');" color="primary">attach_money</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 3 ? '#fdc800' : '#0069b2'}" style="font-size: .6em;">
                {{idiomas.InversionPC | uppercase}}</p>
        </div>
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 3 ? '#fdc800' : '#0069b2'}" class="bottom-menu-icons"
                (click)="move('AhorrosProgramados');" color="primary">folder_open</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 3 ? '#fdc800' : '#0069b2'}" style="font-size: .6em;">
                {{idiomas.AhorroProgramado | uppercase}}</p>
        </div>
    </div>
    <!--Aqui termina el menu expandible de INVERSIONES -->
    <mat-divider *ngIf="tabIndex === 1 || tabIndex === 2 || tabIndex === 3" style="padding-top: 4px; "></mat-divider>
</div>
<!--Aqui termina primer set de items para menu expandible-->

<!--Aqui empieza segundo set de items para menu expandible-->
<div class="form-row" style="width: 100%;">
    <div class="col-4" style="margin-top: 7px; text-align: center !important;">
        <mat-icon *ngIf="tema == 'light-theme'" [ngStyle]="{'color':tabIndex === 4 ? '#fdc800' : '#0069b2'}"
            class="bottom-menu-icons" color="primary" (click)="onTabClick(4);">money</mat-icon>
        <p *ngIf="tema == 'light-theme'" [ngStyle]="{'color':tabIndex === 4 ? '#fdc800' : '#0069b2'}"
            style="font-size: .6em;">{{idiomas.PagosMenu |
            uppercase}}</p>
        <mat-icon *ngIf="tema == 'night-theme'" [ngStyle]="{'color':tabIndex === 4 ? '#fdc800' : '#ffffff'}"
            class="bottom-menu-icons" color="primary" (click)="onTabClick(4);">money</mat-icon>
        <p *ngIf="tema == 'night-theme'" [ngStyle]="{'color':tabIndex === 4 ? '#fdc800' : '#ffffff'}"
            style="font-size: .6em;">{{idiomas.PagosMenu |
            uppercase}}</p>
    </div>
    <div class="col-4" style="margin-top: 7px; text-align: center !important;">
        <mat-icon *ngIf="tema == 'light-theme'" [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#0069b2'}"
            class="bottom-menu-icons" (click)="onTabClick(5);" color="primary">work</mat-icon>
        <p *ngIf="tema == 'light-theme'" [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#0069b2'}"
            style="font-size: .6em;">{{idiomas.lblServicios
            | uppercase}}</p>
        <mat-icon *ngIf="tema == 'night-theme'" [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#ffffff'}"
            class="bottom-menu-icons" (click)="onTabClick(5);" color="primary">work</mat-icon>
        <p *ngIf="tema == 'night-theme'" [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#ffffff'}"
            style="font-size: .6em;">{{idiomas.lblServicios
            | uppercase}}</p>
    </div>
    <div class="col-4" style="margin-top: 7px; text-align: center !important;">
        <mat-icon class="bottom-menu-icons" (click)="move('CertificadoBancario')" color="primary">list_alt</mat-icon>
        <p *ngIf="tema == 'light-theme'" style="font-size: .6em;" [ngStyle]="{'color': '#0069b2'}">
            {{idiomas.CertificadoBancario | uppercase}}</p>
        <p *ngIf="tema == 'night-theme'" style="font-size: .6em;" [ngStyle]="{'color': '#ffffff'}">
            {{idiomas.CertificadoBancario | uppercase}}</p>
    </div>
</div>
<div>
    <mat-divider *ngIf="tabIndex === 4 || tabIndex === 5" style="padding-top: 4px; "></mat-divider>
    <!--Aqui empieza el menu expandible de PRESTAMOS -->
    <div class="form-row" *ngIf="tabIndex === 4">
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 4 ? '#fdc800' : '#0069b2'}" class="bottom-menu-icons"
                (click)="move('PrestamoPreaprobadoT');" color="primary">ads_click</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 4 ? '#fdc800' : '#0069b2'}" style="font-size: .6em;">
                {{idiomas.PrestamoPreaprobadoT | uppercase}}</p>
        </div>
    </div>
    <!--Aqui termina el menu expandible de PRESTAMOS -->

    <!--Aqui empieza el menu expandible de SERVICIOS -->
    <div class="form-row" *ngIf="tabIndex === 5">
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#0069b2'}" class="bottom-menu-icons"
                (click)="move('gestionServicios');" color="primary">receipt_long</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#0069b2'}" style="font-size: .6em;">
                {{idiomas.gestionServicios | uppercase}}</p>
        </div>
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#2675A9'}" class="bottom-menu-icons"
                (click)="move('pagServ');" color="primary">work_outline</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#2675A9'}" style="font-size: .6em;">{{idiomas.lblServicios |
                uppercase}}</p>
        </div>
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#2675A9'}" class="bottom-menu-icons"
                (click)="move('Recargas');" color="primary">local_atm</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#2675A9'}" style="font-size: .6em;">{{idiomas.Recargas |
                uppercase}}</p>
        </div>
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#2675A9'}" class="bottom-menu-icons"
                (click)="move('pagRec');" color="primary">account_balance_wallet</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#2675A9'}" style="font-size: .6em;">{{idiomas.pagRec |
                uppercase}}</p>
        </div>
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#2675A9'}" class="bottom-menu-icons"
                (click)="move('consPagServ');" color="primary">chat_bubble_outline</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#2675A9'}" style="font-size: .6em;">
                {{idiomas.CServ | uppercase}}</p>
        </div>
    </div>
    <!--Aqui termina el menu expandible de SERVICIOS -->
    <mat-divider *ngIf="tabIndex === 4 || tabIndex === 5" style="padding-top: 4px; "></mat-divider>
</div>
<!--Aqui termina segundo set de items para menu expandible-->

<!--Aqui empieza cuarto set de items para menu expandible-->
<div class="form-row" style="width: 100%;">
    <div class="col-4" style="margin-top: 7px; text-align: center !important;">
        <mat-icon *ngIf="tema == 'light-theme'" [ngStyle]="{'color':tabIndex === 8 ? '#fdc800' : '#2675A9'}"
            class="bottom-menu-icons" (click)="onTabClick(8);" color="primary">account_circle</mat-icon>
        <p *ngIf="tema == 'light-theme'" [ngStyle]="{'color':tabIndex === 8 ? '#fdc800' : '#2675A9'}"
            style="font-size: .6em;">
            {{idiomas.Administracion | uppercase}}</p>
        <mat-icon *ngIf="tema == 'night-theme'" [ngStyle]="{'color':tabIndex === 8 ? '#fdc800' : '#ffffff'}"
            class="bottom-menu-icons" (click)="onTabClick(8);" color="primary">account_circle</mat-icon>
        <p *ngIf="tema == 'night-theme'" [ngStyle]="{'color':tabIndex === 8 ? '#fdc800' : '#ffffff'}"
            style="font-size: .6em;">
            {{idiomas.Administracion | uppercase}}</p>
    </div>
    <div class="col-4" style="margin-top: 7px; text-align: center !important;">
        <mat-icon class="bottom-menu-icons" (click)="move('Notificaciones');" color="primary"
            matBadge="{{notificaciones}}" [matBadgeHidden]="notificaciones<=0">notification_important</mat-icon>
        <p *ngIf="tema == 'light-theme'" style="font-size: .6em;" [ngStyle]="{'color': '#2675A9'}">
            {{idiomas.Notificaciones | uppercase}}</p>
        <p *ngIf="tema == 'night-theme'" style="font-size: .6em;" [ngStyle]="{'color': '#ffffff'}">
            {{idiomas.Notificaciones | uppercase}}</p>
    </div>
    <div class="col-4" style="margin-top: 7px; text-align: center !important;">
        <mat-icon class="bottom-menu-icons" (click)="move('tarPend');" color="primary"
            matBadge="{{numTareasPendientes}}" [matBadgeHidden]="numTareasPendientes<=0">history</mat-icon>
        <p *ngIf="tema == 'light-theme'" style="font-size: .6em;" [ngStyle]="{'color': '#2675A9'}">{{idiomas.TarPend |
            uppercase}}</p>
        <p *ngIf="tema == 'night-theme'" style="font-size: .6em;" [ngStyle]="{'color': '#ffffff'}">{{idiomas.TarPend |
            uppercase}}</p>
    </div>
</div>
<div>
    <mat-divider *ngIf="tabIndex === 8 || tabIndex === 9" style="padding-top: 4px; "></mat-divider>
    <!--Aqui empieza el menu expandible de ADMINISTRACION -->
    <div class="form-row" *ngIf="tabIndex === 8">
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 8 ? '#fdc800' : '#2675A9'}" class="bottom-menu-icons"
                (click)="move('MontoMaximoTransferencia');" color="primary">price_change</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 8 ? '#fdc800' : '#2675A9'}" style="font-size: .6em;">
                {{idiomas.MontoMaximoTransferencia | uppercase}}</p>
        </div>
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 8 ? '#fdc800' : '#2675A9'}" class="bottom-menu-icons"
                (click)="move('Administracion');" color="primary">display_settings</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 8 ? '#fdc800' : '#2675A9'}" style="font-size: .6em;">
                {{idiomas.AdministracionCuenta | uppercase}}</p>
        </div>
        <div class="col-3" style="margin-top: 7px; text-align: center !important;">
            <mat-icon [ngStyle]="{'color':tabIndex === 8 ? '#fdc800' : '#2675A9'}" class="bottom-menu-icons"
                (click)="move('btnBloqueoDeCuenta');" color="primary">display_settings</mat-icon>
            <p [ngStyle]="{'color':tabIndex === 8 ? '#fdc800' : '#2675A9'}" style="font-size: .6em;">
                {{idiomas.BloqueoCuenta | uppercase}}</p>
        </div>
    </div>

    <!--Aqui termina el menu expandible de GIROS -->
    <mat-divider *ngIf="tabIndex === 8 || tabIndex === 9" style="padding-top: 4px; "></mat-divider>
</div>
<!--Aqui termina cuarto set de items para menu expandible-->
<div class="form-row" style="width: 100%;">
    <div class="col-4" style="margin-top: 7px; text-align: center !important;">
        <mat-icon class="bottom-menu-icons" (click)="logout(3);" color="primary">exit_to_app</mat-icon>
        <p *ngIf="tema == 'light-theme'" style="font-size: .6em;" [ngStyle]="{'color': '#2675A9'}">
            {{idiomas.CerrarSesion | uppercase}}</p>
        <p *ngIf="tema == 'night-theme'" style="font-size: .6em;" [ngStyle]="{'color': '#ffffff'}">
            {{idiomas.CerrarSesion | uppercase}}</p>
    </div>
</div>