import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogoComponent } from '../dialogo/dialogo.component';
import { DialogoSeguroComponent } from '../dialogo-seguro/dialogo-seguro.component';
import { DialogoUsuarioComponent } from '../dialogo-usuario/dialogo-usuario.component';
import { reject } from 'q';
import { DialogoOfflineComponent } from '../dialogo-offline/dialogo-offline.component';
import { MensajeAlertaComponent } from '../mensaje-alerta/mensaje-alerta.component';

import { internetComponent } from '../funciones/internet'
import { DialogoCompraComponent } from '../dialogo-compra/dialogo-compra.component';
import { DialogoAdministracionVentasComponent } from '../dialogo-administracion-ventas/dialogo-administracion-ventas.component';
import { DialogoAdministracionTiendaComponent } from '../dialogo-administracion-tienda/dialogo-administracion-tienda.component';
import { DialogoAdministracionProductosComponent } from '../dialogo-administracion-productos/dialogo-administracion-productos.component';
import { DialogoPagoPrestamoComponent } from '../dialogo-pago-prestamo/dialogo-pago-prestamo.component';
import { DialogoDetalleTransferenciaComponent } from '../dialogo-detalle-transferencia/dialogo-detalle-transferencia.component';
import { DialogoDetalleServiciosComponent } from '../dialogo-detalle-servicios/dialogo-detalle-servicios.component';
import { DialogoDetalleCuentasComponent } from '../dialogo-detalle-cuentas/dialogo-detalle-cuentas.component';
import { DialogoInicioComponent } from '../dialogo-inicio/dialogo-inicio.component';
import { DialogoTransaccionesComponent } from '../dialogo-transacciones/dialogo-transacciones.component';
import { DialogoEsperandoComponent } from '../dialogo-esperando/dialogo-esperando.component';
import { DialogoRatingComponent } from '../dialogo-rating/dialogo-rating.component';
import { DialogoConfirmacionComponent } from '../dialogo-confirmacion/dialogo-confirmacion.component';
import { DialogoTransaccionesErrorComponent } from '../dialogo-transacciones-error/dialogo-transacciones-error.component';
import { DialogoPlazoComponent } from '../dialogo-plazo/dialogo-plazo.component';
import { DialogoConfirmacionTransferenciaComponent } from '../dialogo-confirmacion-transferencia/dialogo-confirmacion-transferencia.component';
import { DialogoAhorroProgramadoComponent } from '../dialogo-ahorro-programado/dialogo-ahorro-programado.component';
import { DialogoMostrarPdfComponent } from '../dialogo-mostrar-pdf/dialogo-mostrar-pdf.component'
import { DialogoCreditoComponent } from '../dialogo-credito/dialogo-credito.component';
import { DiaologoIOSAndroidInstallComponent } from '../diaologo-i-os-android-install/diaologo-i-os-android-install.component';
import { DialogoEstadoCuentaComponent } from '../dialogo-estado-cuenta/dialogo-estado-cuenta.component';
import { DialogoSolicitarAutorizacionComponent } from '../dialogo-solicitar-autorizacion/dialogo-solicitar-autorizacion.component';
import { DialogoGenerarQRComponent } from '../dialogo-generar-qr/dialogo-generar-qr.component';
import { DialogoCertificadosComponent } from '../dialogo-certificados/dialogo-certificados.component';
import {DialogoContinuarProcesoBeneficiarioComponent} from '../dialogo-continuar-proceso-beneficiario/dialogo-continuar-proceso-beneficiario.component'
import { DialogoBloqueoCuentaComponent } from '../dialogo-bloqueo-cuenta/dialogo-bloqueo-cuenta.component';

@Injectable()
export class Alerta {

    constructor(private snackBar: MatSnackBar, public internet: internetComponent, private dialog: MatDialog) {
    }

    presentarAlertaX(msg: string) {
        //Configuracion de alertasX
        let configSuccess: MatSnackBarConfig = {};
        this.snackBar.openFromComponent(MensajeAlertaComponent, {
            data: msg,
            ...configSuccess
        });
    }

    presentarAlerta(msg: string) {
        let config = new MatSnackBarConfig();
        config.duration = 4000;
        this.snackBar.open(msg, null, config);
    }

    clearAlerta() {
        try {
            this.snackBar.dismiss();
        } catch (error) { }
    }

    revisarInternet() {
        if (!this.internet.internet) {
            this.presentarAlerta('No tienes conexión a Internet')
            return true
        } else {
            return false
        }
    }

    generarOfflineDialogo(info, idm: any) {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;

        dialogConfig.data = {
            info: info,
            idioma: idm
        };
        this.dialog.open(DialogoOfflineComponent, dialogConfig)
    }

    generarDialogo(idm: any, key: string) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                idm1: idm,
                mensaje: idm[key]
            };
            const dialogRef = this.dialog.open(DialogoComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoBloqueoCuenta(idm: any, key: string, intentoConfirmacion: boolean, intentoFelicitacion: boolean) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                idm1: idm,
                mensaje: idm[key],
                intentoConfirmacion: intentoConfirmacion,
                intentoFelicitacion: intentoFelicitacion
            };
            const dialogRef = this.dialog.open(DialogoBloqueoCuentaComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoSeguro(idm: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: idm.SeguroCancelar
            };
            const dialogRef = this.dialog.open(DialogoSeguroComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoPagoPrestamo(idiomas: any, loanPaymentInfo: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.width = '98vw';
            dialogUser.maxWidth = '600px';
            dialogUser.data = {
                idiomas: idiomas,
                loanPaymentInfo: loanPaymentInfo
            };
            const dialogRef = this.dialog.open(DialogoPagoPrestamoComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoDetalleTransferencia(idm: any, data: any, bandConsultaTarjetaCredito: any, i: any, grande, mediano, normal) {
        return new Promise((resolve) => {
            let dialogDetalleTransferencia = new MatDialogConfig();
            dialogDetalleTransferencia.autoFocus = false;
            dialogDetalleTransferencia.width = '94vw';
            dialogDetalleTransferencia.maxWidth = '600px';
            dialogDetalleTransferencia.data = {
                idioma: idm,
                data: data,
                bandConsultaTarjetaCredito: bandConsultaTarjetaCredito,
                i: i,
                fontSize: { grande: grande, mediano: mediano, normal: normal }
            };
            const dialogRef = this.dialog.open(DialogoDetalleTransferenciaComponent, dialogDetalleTransferencia);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoQR(idm: any, data: any, isScanQR: boolean) {
        return new Promise((resolve) => {
            let dialogDetalleTransferencia = new MatDialogConfig();
            dialogDetalleTransferencia.autoFocus = false;
            dialogDetalleTransferencia.width = '94vw';
            dialogDetalleTransferencia.maxWidth = '600px';
            dialogDetalleTransferencia.data = {
                idioma: idm,
                data: data,
                isScanQR: isScanQR
            };
            const dialogRef = this.dialog.open(DialogoGenerarQRComponent, dialogDetalleTransferencia);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoDetalleServicio(idm: any, data: any) {
        return new Promise((resolve) => {
            let dialogDetalleServicio = new MatDialogConfig();
            dialogDetalleServicio.autoFocus = false;
            dialogDetalleServicio.width = '94vw';
            dialogDetalleServicio.maxWidth = '600px';
            dialogDetalleServicio.data = {
                idioma: idm,
                data: data,

            };
            const dialogRef = this.dialog.open(DialogoDetalleServiciosComponent, dialogDetalleServicio);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoDetalleCuentas(idm: any, data: any, producto: any, grande, mediano, normal, showBlocks) {
        return new Promise((resolve) => {
            let dialogDetalleServicio = new MatDialogConfig();
            dialogDetalleServicio.autoFocus = false;
            dialogDetalleServicio.width = '94vw';
            dialogDetalleServicio.maxWidth = '600px';
            dialogDetalleServicio.data = {
                idioma: idm,
                data: data,
                producto: producto,
                fontSize: { grande: grande, mediano: mediano, normal: normal },
                showBlocks: showBlocks
            };
            const dialogRef = this.dialog.open(DialogoDetalleCuentasComponent, dialogDetalleServicio);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoSeguroInicio(idm: any, nombreUsuario: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.autoFocus = false;
            dialogUser.maxWidth = '94vw';
            dialogUser.data = {
                id: 2,
                idm1: idm,
                nombreUsuario: nombreUsuario
            };
            const dialogRef = this.dialog.open(DialogoInicioComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoSeguroRating(idm: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.autoFocus = false;
            dialogUser.maxWidth = '94vw';
            dialogUser.data = {
                id: 2,
                idm1: idm,
            };
            const dialogRef = this.dialog.open(DialogoRatingComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoSegurotransacciones(idm: any, numeroMensaje: any, intentoTransferPagoServ: boolean, intentoEnvioTransPagRec: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.autoFocus = false;
            dialogUser.width = '94vw';
            dialogUser.maxWidth = '700px';
            dialogUser.data = {
                id: 2,
                idm1: idm,
                numeroMensaje: numeroMensaje,
                intentoTransferPagoServ: intentoTransferPagoServ,
                intentoEnvioTransPagRec: intentoEnvioTransPagRec
            };
            const dialogRef = this.dialog.open(DialogoTransaccionesComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoSegurotransaccionesBanred(idm: any, numeroMensaje: any, intentoTransferPagoServ: boolean, intentoEnvioTransPagRec: any, intentoBanred:boolean) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.autoFocus = false;
            dialogUser.maxWidth = '95vw';
            dialogUser.data = {
                id: 2,
                idm1: idm,
                numeroMensaje: numeroMensaje,
                intentoTransferPagoServ: intentoTransferPagoServ,
                intentoEnvioTransPagRec: intentoEnvioTransPagRec,
                intentoBanred:intentoBanred
            };
            const dialogRef = this.dialog.open(DialogoTransaccionesComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoSeguroErrortransacciones(idm: any, mensaje: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.autoFocus = false;
            dialogUser.width = '94vw';
            dialogUser.maxWidth = '600px';
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: mensaje
            };
            const dialogRef = this.dialog.open(DialogoTransaccionesErrorComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoCompra(idm: any, params: any) {
        return new Promise((resolve) => {
            let dialogCompra = new MatDialogConfig();
            dialogCompra.autoFocus = false;
            dialogCompra.width = '800px'
            dialogCompra.data = {
                id: 1,
                idioma: idm,
                parametros: params,
            };
            dialogCompra.panelClass = 'dialog-ecommerce'
            const dialogRef = this.dialog.open(DialogoCompraComponent, dialogCompra);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoAdminProduct(idm: any, params: any, adm: any, carac1: any, carac2: any, ctienda: any) {
        return new Promise((resolve) => {
            let dialogNewProduct = new MatDialogConfig();
            dialogNewProduct.width = '800px'
            dialogNewProduct.data = {
                id: 1,
                idioma: idm,
                parametros: params,
                adm: adm,
                carac1: carac1,
                carac2: carac2,
                ctienda: ctienda
            };
            dialogNewProduct.panelClass = 'dialog-ecommerce'
            const dialogRef = this.dialog.open(DialogoAdministracionProductosComponent, dialogNewProduct);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoAdmiEditStore(idm: any, adm: any, value: any) {
        return new Promise((resolve) => {
            let dialogEditStore = new MatDialogConfig();
            dialogEditStore.width = '800px'
            dialogEditStore.data = {
                id: 1,
                idioma: idm,
                adm: adm,
                value: value
            };
            dialogEditStore.panelClass = 'dialog-ecommerce'
            const dialogRef = this.dialog.open(DialogoAdministracionTiendaComponent, dialogEditStore);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoAdminTienda(idm: any, datos: any) {
        return new Promise((resolve) => {
            let dialogConfirmSale = new MatDialogConfig();
            dialogConfirmSale.width = '800px'
            dialogConfirmSale.data = {
                id: 1,
                idioma: idm,
                datos: datos
            };
            dialogConfirmSale.panelClass = 'dialog-ecommerce'
            const dialogRef = this.dialog.open(DialogoAdministracionVentasComponent, dialogConfirmSale);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoSeguroSesion(idm: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: idm.msjalertaSesion + " " + idm.msjCerrarSesion
            };
            const dialogRef = this.dialog.open(DialogoSeguroComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoSeguroContrato(idm: any, imagen: any, reqJSON) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: idm.msjContrato,
                imagen: imagen,
                reqJSON: reqJSON
            };
            const dialogRef = this.dialog.open(DialogoSeguroComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoConMensajeDeIdiomas(idm: any, key: string) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: idm.SeguroContinuar + ' ' + idm[key]
            };
            const dialogRef = this.dialog.open(DialogoSeguroComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoTransferenciasSeguro(transaccion: string, idm: any, mensajeData: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: transaccion.toUpperCase() + ' ' + mensajeData
            };
            const dialogRef = this.dialog.open(DialogoSeguroComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        });
    }

    generarDialogoConfirmacionTransferencias(transaccion: string, evento: any, idm: any, mensajeData: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.autoFocus = false;
            dialogUser.width = '94vw';
            dialogUser.maxWidth = '600px';
            dialogUser.data = {
                transaccion: transaccion,
                idm: idm,
                evento: evento,
                mensajeData: mensajeData
            };
            const dialogRef = this.dialog.open(DialogoConfirmacionTransferenciaComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        });
    }

    generarDialogoSeguroConfirmacion(idm: any, intentoConfirmacion: boolean, intentoFelicitacion: boolean) {
        return new Promise((resolve) => {
            let dialogConfirmacion = new MatDialogConfig();
            dialogConfirmacion.autoFocus = false;
            dialogConfirmacion.data = {
                idm1: idm,
                intentoConfirmacion: intentoConfirmacion,
                intentoFelicitacion: intentoFelicitacion
            };
            const dialogRef = this.dialog.open(DialogoConfirmacionComponent, dialogConfirmacion);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        });
    }

    generarDialogoSeguroTX(idm: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: idm.SegContTx
            };
            const dialogRef = this.dialog.open(DialogoSeguroComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoLogin(i, s: any, idm: any, token) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            if (i != 'depurarBase') {
                dialogUser.data = {
                    id: i,
                    salir: s,
                    idioma: idm,
                    jwt: token
                };
            }
            else {
                dialogUser.data = {
                    id: i,
                    salir: false,
                    dias: s,
                    idioma: idm
                };
            }
            const dialogRef = this.dialog.open(DialogoUsuarioComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoGuardarUsuario(i, idm: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: i,
                idioma: idm
            };
            const dialogRef = this.dialog.open(DialogoUsuarioComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoEliminarTarea(i, s: any, idm: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: i,
                salir: s,
                idioma: idm,
                bandReport: false
            };
            const dialogRef = this.dialog.open(DialogoUsuarioComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoReporte(id, idm: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: id,
                salir: false,
                idioma: idm
            };
            if (id == 'Reporte') {
                dialogUser['data']['bandReport'] = true
                dialogUser['data']['bandReportServ'] = false
            } else {
                dialogUser['data']['bandReport'] = false
                dialogUser['data']['bandReportServ'] = true
            }
            const dialogRef = this.dialog.open(DialogoUsuarioComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoSeguroRegistrarHuella(idm: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: idm.RegistrarHuella
            };
            const dialogRef = this.dialog.open(DialogoSeguroComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoInversion(transaccion: string, idm: any, mensajeData: any, tipo: string) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = false;
            dialogUser.autoFocus = false;
            dialogUser.height = tipo == "mostrarTexto" ? '' : '77%';
            dialogUser.width = tipo == "mostrarTexto" ? '94vw' : '98vw';
            dialogUser.maxWidth = tipo == "mostrarTexto" ? '600px' : '95vw';
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: mensajeData,
                tipo: tipo
            };
            const dialogRef = this.dialog.open(DialogoPlazoComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        });
    }

    generarDialogoSeguroPreaprobado(idm: any, string: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: string
            };
            const dialogRef = this.dialog.open(DialogoSeguroComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoEsperando(idm: any, message: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.autoFocus = false;
            dialogUser.maxWidth = '94vw';
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: message
            };
            const dialogRef = this.dialog.open(DialogoEsperandoComponent, dialogUser);
            dialogRef.afterOpened().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoAHProgramado(idm: any, mensajeData: any, tipo: string) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = false;
            dialogUser.autoFocus = false;
            dialogUser.height = tipo == "mostrarTexto" ? '' : '77%';
            dialogUser.width = tipo == "mostrarTexto" ? '' : '98vw';
            dialogUser.maxWidth = tipo == "mostrarTexto" ? '' : '95vw';
            dialogUser.data = {
                id: 2,
                idm1: idm,
                tipo: tipo,
                mensaje: mensajeData
            };
            const dialogRef = this.dialog.open(DialogoAhorroProgramadoComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        });
    }

    generarDialogoAmpliacionTerminos(idm: any, base64URL: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.width = '94vw';
            dialogUser.maxWidth = '600px';
            dialogUser.data = {
                id: 2,
                idm: idm,
                base64URL: base64URL
            };
            const dialogRef = this.dialog.open(DialogoMostrarPdfComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        });
    }

    generarDialogoSeguroContinuar(idm: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: idm.SeguroContinuar
            };
            const dialogRef = this.dialog.open(DialogoSeguroComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    cancelar() {
        this.snackBar.dismiss()
    }

    generarDialogoCreditoAprobado(idm: any, string: any, mensajeData: any, tipo: string) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.height = tipo == "mostrarTexto" ? '' : '77%';
            dialogUser.width = tipo == "mostrarTexto" ? '94vw' : '98vw';
            dialogUser.maxWidth = tipo == "mostrarTexto" ? '600px' : '95vw';
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: string,
                mensajeData: mensajeData,
                tipo: tipo
            };
            const dialogRef = this.dialog.open(DialogoCreditoComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoAppleAndroidInstall(idm: any, bandIos: any, appInstall: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.width = '94vw';
            dialogUser.maxWidth = '600px';
            dialogUser.data = {
                id: 1,
                idm1: idm,
                bandIos: bandIos,
                appInstall: appInstall
            };
            const dialogRef = this.dialog.open(DiaologoIOSAndroidInstallComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoAndroidInstall(idm: any, bandIos: any, appInstall: any, event: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.width = '94vw';
            dialogUser.maxWidth = '600px';
            dialogUser.height = '90%';
            dialogUser.data = {
                id: 1,
                idm1: idm,
                bandIos: bandIos,
                appInstall: appInstall,
                event: event
            };
            const dialogRef = this.dialog.open(DiaologoIOSAndroidInstallComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoSeguroCaducar(idm: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: idm.SeguroContinuar
            };
            const dialogRef = this.dialog.open(DialogoSeguroComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoSeguroRealizarTransferencia(idm: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: idm.SeguroContinuarTransferencia
            };
            const dialogRef = this.dialog.open(DialogoSeguroComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoEstadoCuenta(idm: any, ccuenta: any, fdesde: any, fhasta: any, sinicial: any, cmoneda: any, available: any, blocked: any, grande: any, mediano: any, normal: any) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.autoFocus = false;
            dialogUser.width = '94vw';
            dialogUser.maxWidth = '600px';
            dialogUser.data = {
                idm: idm,
                ccuenta: ccuenta,
                fdesde: fdesde,
                fhasta: fhasta,
                sinicial: sinicial,
                cmoneda: cmoneda,
                available: available,
                blocked: blocked,
                fontSize: { grande: grande, mediano: mediano, normal: normal }
            };
            const dialogRef = this.dialog.open(DialogoEstadoCuentaComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        });
    }

    generarDialogoSolicitarAutorizacionData(idioma: any, grande: boolean, mediano: boolean, info: any, tokenInfo: string) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = true;
            dialogUser.autoFocus = false;
            dialogUser.data = {
                idm: idioma,
                grande: grande,
                mediano: mediano,
                info: info,
                tokenInfo: tokenInfo
            };
            const dialogRef = this.dialog.open(DialogoSolicitarAutorizacionComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarDialogoCertificados(mensaje: string, idm: any, pdfConverted: any, tipo: string, downloadOption:boolean) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.disableClose = false;
            dialogUser.autoFocus = false;
            dialogUser.width = '98vw';
            dialogUser.maxWidth = '600px';
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: mensaje.toUpperCase(),
                pdfConverted: pdfConverted,
                tipo: tipo,
                downloadOption:downloadOption
            };
            const dialogRef = this.dialog.open(DialogoCertificadosComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        });
    }

    generarDialogoSeguroErrortransaccionesBanred(idm: any, mensaje: any, isBanred: boolean) {
        return new Promise((resolve) => {
            let dialogUser = new MatDialogConfig();
            dialogUser.autoFocus = false;
            dialogUser.maxWidth = '95vw';
            dialogUser.data = {
                id: 2,
                idm1: idm,
                mensaje: mensaje,
                isBanred: isBanred
            };
            const dialogRef = this.dialog.open(DialogoTransaccionesErrorComponent, dialogUser);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        })
    }

    generarContinuarProcesoBeneficiario(idm: any,  message: string) {
        return new Promise((resolve) => {
            let dialogConfirmacion = new MatDialogConfig();
            dialogConfirmacion.autoFocus = false;
            dialogConfirmacion.data = {
                idm1: idm,
                message: message
            };
            const dialogRef = this.dialog.open(DialogoContinuarProcesoBeneficiarioComponent, dialogConfirmacion);
            dialogRef.afterClosed().subscribe(data => {
                resolve(data)
            })
        });
    }

}