import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-dialogo-bloqueo-cuenta',
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatDialogModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, FlexLayoutModule],
  templateUrl: './dialogo-bloqueo-cuenta.component.html',
  styleUrl: './dialogo-bloqueo-cuenta.component.css'
})
export class DialogoBloqueoCuentaComponent {
  mensaje: string;
  aceptar: string;
  rechazar: string;

  idiomas: any;

  er: boolean = false;
  intento: boolean;
  intentoConfirmacion: boolean;
  intentoFelicitacion: boolean;

  constructor(public apiService: ApiService, private dialogRef: MatDialogRef<DialogoBloqueoCuentaComponent>, @Inject(MAT_DIALOG_DATA) data, public basedatos:
    BaseDatos) {
    this.aceptar = data.idm1.Aceptar;
    this.rechazar = data.idm1.Rechazar;
    this.idiomas = data.idm1;
    this.intentoConfirmacion = data.intentoConfirmacion;
    this.intentoFelicitacion = data.intentoFelicitacion;
  }

  accept() {
    if (this.intentoFelicitacion) {
      this.dialogRef.close(this.er);
    }
    if (this.intentoConfirmacion) {
      this.dialogRef.close(this.er = true);
    }
  }

  close() {
    this.dialogRef.close(this.er);
  }

}
