<mat-sidenav-container [style.background-image]="backgroundImg"
    (swipeleft)="!isLargeScreenForSideNav() ? cerrarMenu($event) : null" class="sidenav-container"
    [class.sidenav-contoffline]="!internetC.internet">
    <mat-sidenav (keyup.esc)="resizeToolbar()" [mode]="isLargeScreenForSideNav() ? 'side' : 'over'" id="snav" #snav
        class="sidenav" (click)="selectedSNav(true)"
        style="min-width: 305px; max-width: 305px; position: fixed;padding-right: 0px;padding-bottom: 20px;">
        <div class="sidenav-header-container">
            <div style="width: 250px; font-weight: 500; padding-top: 10px; padding-bottom: 5px !important;">
                <strong>
                    <p class="sidenav-header-title" style="text-align: justify;margin-left: 15px;">{{idiomas.MiCuenta}}
                    </p>
                </strong>
                <div class="sidenav-header-content">
                    <div style="width: 250px; margin-left: 15px; padding-top: 10px;">
                        <p class="sidenav-header-element" style="font-size: 15px;">{{loginInfo.nombrePreferido}}
                        </p>
                        <p class="sidenav-header-element" style="font-size: 10px">{{loginInfo.email}}
                        </p>
                    </div>
                    <div style="width: 250px; margin-left: 15px;  padding-bottom: 10px;">
                        <p class="sidenav-header-element" style="font-size: 10px;">{{idiomas.CuentaA}}
                            {{loginInfo.cuenta}}
                        </p>
                        <p class="sidenav-header-element" style="font-size: 8.5px;">
                            {{idiomas.UltimaConexion}}: {{loginInfo.lastLogin.split(',')[0]}} <br>
                            {{loginInfo.lastLogin.split(',')[1]}}
                        </p>
                    </div>
                </div>

                <div style="text-align: center; padding-top: 5px;">
                    <a href="https://wa.me/593987436070" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 10px;"><img
                            width="25" height="25"
                            src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-whatsapp.png"
                            class="vc_single_image-img attachment-full" alt="WhatsApp" loading="lazy" priority></a>
                    <a href="https://www.facebook.com/Coopadrejulian/" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 10px;"><img
                            width="25" height="25"
                            src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-facebook.png"
                            class="vc_single_image-img attachment-full" alt="" loading="lazy" priority></a>
                    <a href="https://www.instagram.com/coopadrejulian/" target="_blank"
                        class="vc_single_image-wrapper   vc_box_border_grey" style="padding-right: 10px;"><img
                            width="25" height="25"
                            src="https://lorente.fin.ec/wp-content/uploads/2021/07/lorente-instagra.png"
                            class="vc_single_image-img attachment-full" alt="" loading="lazy" priority></a>
                </div>
            </div>
        </div>
        <div style="margin-top: 10px; margin-left: 15px; width: 140px;">
            <p style="font-size: 10px; margin-bottom: 0px;">PADRE JULIÁN LORENTE</p>
            <p style="font-size: 12px; font-weight: 500; margin-bottom: 5px;">{{idiomas.ServiciosFinancieros}}</p>
        </div>
        <mat-divider style="padding-top: 4px;"></mat-divider>
        <mat-nav-list #matNavList>
            <mat-accordion>
                <!--Aqui empieza el menu de POSICION CONSOLIDADA -->
                <a mat-list-item id="PosicionConsolidada"
                    (click)="move('PosicionConsolidada'); selectedSNav(true); closePanels();"
                    [class.medianosidenav]="mediano" [class.grandesidenav]="grande" [class.normalsidenav]="normal"
                    style="font-size: 14px">
                    <mat-icon style="padding-right: 30px;">pie_chart</mat-icon>
                    {{idiomas.PosicionConsolidada}}
                </a>
                <div style="padding-top: 5px"></div>
                <mat-divider style="padding-top: 5px; "></mat-divider>
                <!--Aqui termina el menu de POSICION CONSOLIDADA -->

                <!--Aqui empieza el menu expandible de TRANSFERENCIAS -->
                <mat-expansion-panel #transferPanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title id="Transferencias" [class.medianosidenav]="mediano"
                            [class.grandesidenav]="grande" [class.normalsidenav]="normal" style="font-size: 14px;">
                            <mat-icon style="padding-right: 30px">compare_arrows</mat-icon>
                            {{idiomas.lblTransferenciasMob}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a mat-list-item id="TransferenciasCuentasPropias" (click)="move('TransferenciasCuentasPropias')"
                        [class.medianosidenav]="mediano" [class.grandesidenav]="grande" [class.normalsidenav]="normal"
                        style="font-size: 14px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/TRANSFERENCIASDARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/TRANSFERENCIAS.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.TransferenciasCuentasPropias}}
                    </a>
                    <a mat-list-item id="Beneficiarios" (click)="move('Beneficiarios')" [class.medianosidenav]="mediano"
                        [class.grandesidenav]="grande" [class.normalsidenav]="normal" style="font-size: 14px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/TRANSFERENCIASDARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/TRANSFERENCIAS.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.lblTransferenciasMob}}
                    </a>
                    <a mat-list-item id="CTransferencias" (click)="move('CTransferencias')"
                        [class.medianosidenav]="mediano" [class.grandesidenav]="grande" [class.normalsidenav]="normal"
                        style="font-size: 14px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/CONSULTASDARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/CONSULTAS.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.CTrans}}
                    </a>
                </mat-expansion-panel>
                <div style="padding-top: 5px"></div>
                <mat-divider style="padding-top: 5px; "></mat-divider>
                <!--Aqui termina el menu expandible de TRANSFERENCIAS -->

                <!--Aqui empieza el menu expandible de TRANSFERENCIAS TARJETAS-->
                <mat-expansion-panel #creditCardPanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title [class.medianosidenav]="mediano" [class.grandesidenav]="grande"
                            [class.normalsidenav]="normal" style="font-size: 14px;">
                            <mat-icon style="padding-right: 30px">credit_card</mat-icon>
                            {{idiomas.Tarjeta}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <a mat-list-item id="TarjetasCredito" (click)="move('TarjetasCredito')"
                        [class.medianosidenav]="mediano" [class.grandesidenav]="grande" [class.normalsidenav]="normal"
                        style="font-size: 14px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/TRANSFERENCIASDARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/TRANSFERENCIAS.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.TransferenciasTarjetaCredito}}
                    </a>
                    <a mat-list-item id="CTransferenciasTarjeta" (click)="move('CTransferenciasTarjeta')"
                        [class.medianosidenav]="mediano" [class.grandesidenav]="grande" [class.normalsidenav]="normal"
                        style="font-size: 14px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/CONSULTASDARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/CONSULTAS.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.CTarj}}
                    </a>
                </mat-expansion-panel>
                <div style="padding-top: 5px"></div>
                <mat-divider style="padding-top: 5px; "></mat-divider>
                <!--Aqui termina el menu expandible de TRANSFERENCIAS TARJETAS -->


                <!--Aqui empieza el menu para INVERSIONES -->
                <a mat-list-item id="plazoFijo" (click)="move('plazoFijo')" [class.medianosidenav]=mediano
                    [class.grandesidenav]=grande style="font-size: 14px;">
                    <mat-icon style=" padding-right: 30px;">monetization_on</mat-icon>
                    {{idiomas.DepositoP}}
                </a>
                <div style="padding-top: 5px"></div>
                <mat-divider style="padding-top: 5px; "></mat-divider>
                <!--Aqui termina el menu expandible para INVERSIONES -->

                <!--Aqui empieza el menu para AHORRO PROGRAMADO -->
                <a mat-list-item id="AhorrosProgramados" (click)="move('AhorrosProgramados')"
                    [class.medianosidenav]=mediano [class.grandesidenav]=grande style="font-size: 14px;">
                    <mat-icon style=" padding-right: 30px;">local_atm</mat-icon>
                    {{idiomas.AhorroProgramado}}
                </a>
                <div style="padding-top: 5px"></div>
                <mat-divider style="padding-top: 5px; "></mat-divider>
                <!--Aqui termina el menu expandible de  AHORRO PROGRAMADO -->

                <!--Aqui empieza el menu para CREDITO PREAPROBADO -->
                <a mat-list-item id="PrestamoPreaprobadoT" (click)="move('PrestamoPreaprobadoT')"
                    [class.medianosidenav]=mediano [class.grandesidenav]=grande style="font-size: 14px;">
                    <mat-icon style=" padding-right: 30px;">money</mat-icon>
                    {{idiomas.PrestamoPreaprobadoT}}
                </a>
                <div style="padding-top: 5px"></div>
                <mat-divider style="padding-top: 5px; "></mat-divider>
                <!--Aqui termina el menu expandible de CREDITO PREAPROBADO -->

                <!--Aqui empieza el menu de CertificadosBancarios -->
                <a mat-list-item id="CertificadoBancario" (click)="move('CertificadoBancario')"
                    [class.medianosidenav]=mediano [class.grandesidenav]=grande style="font-size: 14px;">
                    <mat-icon style="padding-right: 30px;">move_down</mat-icon>
                    {{idiomas.Certificados}}
                </a>
                <div style="padding-top: 5px"></div>
                <mat-divider style="padding-top: 5px; "></mat-divider>
                <!--Aqui termina el menu de CertificadosBancarios -->

                <!--Aqui empieza el menu expandible de SERVICIOS -->
                <mat-expansion-panel #servicePanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title [class.normalsidenav]="normal" [class.medianosidenav]="mediano"
                            [class.grandesidenav]="grande" style="font-size: 14px;">
                            <mat-icon style="padding-right: 30px">work</mat-icon>
                            {{idiomas.lblServicios}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a mat-list-item id="gestionServicios" (click)="move('gestionServicios')"
                        [class.normalsidenav]="normal" [class.medianosidenav]="mediano" [class.grandesidenav]="grande"
                        style="font-size: 14px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/BENEFICIARIOSDARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/BENEFICIARIOS.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.gestionServicios}}
                    </a>
                    <a mat-list-item id="pagServ" (click)="move('pagServ')" [class.normalsidenav]="normal"
                        [class.medianosidenav]="mediano" [class.grandesidenav]="grande" style="font-size: 14px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/SERVICIOSDARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/SERVICIOS.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.pagServ}}
                    </a>
                    <a mat-list-item id="Recargas" (click)="move('Recargas')" [class.normalsidenav]="normal"
                        [class.medianosidenav]="mediano" [class.grandesidenav]="grande" style="font-size: 14px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/SERVICIOSDARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/SERVICIOS.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.Recargas}}
                    </a>
                    <a mat-list-item id="pagRec" (click)="move('pagRec')" [class.normalsidenav]="normal"
                        [class.medianosidenav]="mediano" [class.grandesidenav]="grande" style="font-size: 14px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/SERVICIOSDARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/SERVICIOS.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.pagRec}}
                    </a>
                    <a mat-list-item id="consPagServ" (click)="move('consPagServ')" [class.normalsidenav]="normal"
                        [class.medianosidenav]="mediano" [class.grandesidenav]="grande" style="font-size: 14px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/CONSULTASDARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/CONSULTAS.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.CServ}}
                    </a>
                </mat-expansion-panel>
                <div style="padding-top: 5px"></div>
                <mat-divider style="padding-top: 5px; "></mat-divider>
                <!--Aqui termina el menu expandible de SERVICIOS -->


                <!--Aqui empieza el menu expandible de ADMINISTRACION -->
                <mat-expansion-panel #adminPanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title [class.medianosidenav]="mediano" [class.grandesidenav]="grande"
                            [class.normalsidenav]="normal" style="font-size: 14px;">
                            <mat-icon style="padding-right: 30px">account_circle</mat-icon>
                            {{idiomas.Administracion}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a mat-list-item id="MontoMaximoTransferencia" (click)="move('MontoMaximoTransferencia')"
                        [class.medianosidenav]=mediano [class.grandesidenav]=grande
                        style="font-size: 14px; padding-top: 6px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/AHORRODARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/AHORRO.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.MontoMaximoTransferencia}}
                    </a>
                    <a mat-list-item id="Administracion" (click)="move('Administracion')" [class.medianosidenav]=mediano
                        [class.grandesidenav]=grande style="font-size: 14px; padding-top: 6px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/ADMINISTRACIONDECUENTADARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/ADMINISTRACIONDECUENTA.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.Administracion}}
                    </a>
                    <!-- <a mat-list-item id="BtnContactAsesor" (click)="move('BtnContactAsesor')"
                        [class.medianosidenav]=mediano [class.grandesidenav]=grande
                        style="font-size: 14px; padding-top: 6px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/WEBCHATPJL.png"
                            style="padding-right: 5px; width: 25px; height: 20px;"
                            [class.offline]=!internetC.internet priority/>
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/WEBCHATPJL.png"
                            style="padding-right: 5px; width: 25px; height: 20px;"
                            [class.offline]=!internetC.internet priority/>
                        {{idiomas.BtnContactAsesor}}
                    </a> -->
                    <a mat-list-item id="btnCambiarCalif" (click)="move('btnCambiarCalif')"
                        [class.medianosidenav]=mediano [class.grandesidenav]=grande
                        style="font-size: 14px; padding-top: 6px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/BENEFICIARIOSDARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/BENEFICIARIOS.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.BtnCalificar}}
                    </a>
                    <a mat-list-item id="btnCambiarPreg" (click)="move('btnCambiarPreg')" [class.medianosidenav]=mediano
                        [class.grandesidenav]=grande style="font-size: 14px; padding-top: 6px;">
                        <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/CONSULTASDARK.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/CONSULTAS.png"
                            style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                            priority />
                        {{idiomas.BtnPreguntas}}
                    </a>
                    <a mat-list-item id="btnBloqueoDeCuenta" (click)="move('btnBloqueoDeCuenta')" [class.medianosidenav]=mediano
                    [class.grandesidenav]=grande style="font-size: 14px; padding-top: 6px;">
                    <img *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/CONSULTASDARK.png"
                        style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                        priority />
                    <img *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/CONSULTAS.png"
                        style="padding-right: 5px; width: 25px; height: 20px;" [class.offline]=!internetC.internet
                        priority />
                    {{idiomas.btnBloqueoCuenta}}
                </a>
                </mat-expansion-panel>
                <div style="padding-top: 5px"></div>
                <mat-divider style="padding-top: 5px; "></mat-divider>
                <!--Aqui termina el menu expandible de ADMINISTRACION -->

                <!--Aqui empieza el menu expandible de NOTIFICACIONES -->
                <a mat-list-item id="Notificaciones"
                    (click)="move('Notificaciones'); selectedSNav(true); closePanels();"
                    [class.medianosidenav]="mediano" [class.grandesidenav]="grande" [class.normalsidenav]="normal"
                    style="font-size: 14px;">
                    <mat-icon style="padding-right: 30px;">notification_important</mat-icon>
                    {{idiomas.Notificaciones}}
                    <div *ngIf="notificaciones > 0">
                        <button class="btn btnNotificacion" color="primary">
                            {{notificaciones}}
                        </button>
                    </div>
                </a>
                <div style="padding-top: 5px"></div>
                <mat-divider style="padding-top: 5px; "></mat-divider>
                <!--Aqui termina el menu expandible de NOTIFICACIONES -->

                <!--Aqui empieza el menu para TAREAS PENDIENTES -->
                <a mat-list-item id="tarPend" (click)="move('tarPend'); selectedSNav(true); closePanels();"
                    [class.medianosidenav]="mediano" [class.grandesidenav]="grande" [class.normalsidenav]="normal"
                    style="font-size: 14px;">
                    <mat-icon style=" padding-right: 30px;">history</mat-icon><span matBadge="{{numTareasPendientes}}"
                        matBadgeOverlap="false" [matBadgeHidden]="numTareasPendientes<=0">{{idiomas.TarPend}}</span>
                </a>
                <div style="padding-top: 5px"></div>
                <mat-divider style="padding-top: 5px; "></mat-divider>
                <!--Aqui termina el menu para TAREAS PENDIENTES -->

                <!--Aqui empieza el menu para CERRAR SESION -->
                <a mat-list-item (click)="logout(); selectedSNav(true); closePanels();" [class.medianosidenav]="mediano"
                    [class.grandesidenav]="grande" [class.normalsidenav]="normal" style="font-size: 14px;">
                    <mat-icon style=" padding-right: 30px;">exit_to_app</mat-icon>{{idiomas.CerrarSesion}}
                </a>
                <div style="padding-top: 5px"></div>
                <mat-divider style="padding-top: 5px; "></mat-divider>
                <!--Aqui termina el menu para CERRAR SESION -->
            </mat-accordion>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content id="contentHome">
        <div #toolBar id="tool-bar" class="static-toolbar">
            <mat-toolbar class="cabecera" [class.offline]=!internetC.internet>
                <mat-toolbar-row *ngIf="!bandMobile">
                    <button mat-icon-button class="boton" id="btnMenu"
                        (click)="(snav.opened)?cerrarMenu($event):abrirMenu($event)">
                        <mat-icon id="btnMenunotificacion" matBadge="{{notificaciones}}"
                            [matBadgeHidden]="notificaciones<=0">menu
                        </mat-icon>
                    </button>
                    <div class="row divlogos">
                        <img class="col-3" *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/logo_clientDark.png"
                            class="imagen3" [class.offline]=!internetC.internet priority />
                        <img class="col-3" *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/logo_client.png"
                            class="imagen3" [class.offline]=!internetC.internet priority />
                    </div>
                    <div class="row" style="margin-right: 1px; margin-left: 1px" *ngIf=!internetC.internet>
                        <mat-icon>wifi_off</mat-icon>
                    </div>
                    <button mat-icon-button class="boton2" id="btnCerrarSesion" (click)="doBlur()"
                        [matMenuTriggerFor]="CerrarSesion">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #CerrarSesion="matMenu">
                        <a mat-menu-item (click)=logout()>{{idiomas.CerrarSesion}}</a>
                        <a mat-menu-item [matMenuTriggerFor]="Idioma">{{idiomas.Idioma}}</a>
                        <mat-menu #Idioma="matMenu">
                            <a mat-menu-item [class.menuItemSelected]="locale=='es'"
                                [class.menuItemNoSelected]="locale!='es'" (click)=espanol()>{{idiomas.Espanol}}</a>
                            <a mat-menu-item [class.menuItemSelected]="locale=='en'"
                                [class.menuItemNoSelected]="locale!='en'" (click)=ingles()>{{idiomas.Ingles}}</a>
                        </mat-menu>
                        <a mat-menu-item [matMenuTriggerFor]="Fuente">{{idiomas.TamanoFuente}}</a>
                        <mat-menu #Fuente="matMenu">
                            <a mat-menu-item [class.menuItemSelected]="grande" [class.menuItemNoSelected]="!grande"
                                (click)=Grande(false)>{{idiomas.Grande}}</a>
                            <a mat-menu-item [class.menuItemSelected]="mediano" [class.menuItemNoSelected]="!mediano"
                                (click)=Mediano(false)>{{idiomas.Mediana}}</a>
                            <a mat-menu-item [class.menuItemSelected]="normal" [class.menuItemNoSelected]="!normal"
                                (click)=Normal(false)>{{idiomas.Pequena}}</a>
                        </mat-menu>
                        <!--<a mat-menu-item [matMenuTriggerFor]="Tema">{{idiomas.Tema}}</a>
                        <mat-menu #Tema="matMenu">
                            <a mat-menu-item [class.menuItemSelected]="temaSeleccionadoBand==0"
                                [class.menuItemNoSelected]="temaSeleccionadoBand!=0"
                                (click)=cambiarTema(0)>{{idiomas.TemaOscuro}}</a>
                            <a mat-menu-item [class.menuItemSelected]="temaSeleccionadoBand==1"
                                [class.menuItemNoSelected]="temaSeleccionadoBand!=1"
                                (click)=cambiarTema(1)>{{idiomas.TemaClaro}}</a>
                        </mat-menu>-->
                    </mat-menu>
                </mat-toolbar-row>
                <mat-toolbar-row *ngIf="bandMobile">
                    <div class="row divlogos">
                        <img class="col-3" *ngIf="logoTemaOscuro" src="assets/resources/mipmap-hdpi/imagen-login.jpg"
                            class="imagen3" [class.offline]=!internetC.internet priority />
                        <img class="col-3" *ngIf="!logoTemaOscuro" src="assets/resources/mipmap-hdpi/imagen-login.jpg"
                            class="imagen3" [class.offline]=!internetC.internet priority />
                    </div>
                    <div class="row" style="margin-right: 1px; margin-left: 1px" *ngIf=!internetC.internet>
                        <mat-icon>wifi_off</mat-icon>
                    </div>
                    <button mat-icon-button class="boton2" id="btnCerrarSesion" (click)="doBlur()"
                        [matMenuTriggerFor]="CerrarSesion">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #CerrarSesion="matMenu">
                        <a mat-menu-item (click)=logout()>{{idiomas.CerrarSesion}}</a>
                        <a mat-menu-item [matMenuTriggerFor]="Idioma">{{idiomas.Idioma}}</a>
                        <mat-menu #Idioma="matMenu">
                            <a mat-menu-item [class.menuItemSelected]="locale=='es'"
                                [class.menuItemNoSelected]="locale!='es'" (click)=espanol()>{{idiomas.Espanol}}</a>
                            <a mat-menu-item [class.menuItemSelected]="locale=='en'"
                                [class.menuItemNoSelected]="locale!='en'" (click)=ingles()>{{idiomas.Ingles}}</a>
                        </mat-menu>
                        <a mat-menu-item [matMenuTriggerFor]="Fuente">{{idiomas.TamanoFuente}}</a>
                        <mat-menu #Fuente="matMenu">
                            <a mat-menu-item [class.menuItemSelected]="grande" [class.menuItemNoSelected]="!grande"
                                (click)=Grande(false)>{{idiomas.Grande}}</a>
                            <a mat-menu-item [class.menuItemSelected]="mediano" [class.menuItemNoSelected]="!mediano"
                                (click)=Mediano(false)>{{idiomas.Mediana}}</a>
                            <a mat-menu-item [class.menuItemSelected]="normal" [class.menuItemNoSelected]="!normal"
                                (click)=Normal(false)>{{idiomas.Pequena}}</a>
                        </mat-menu>
                        <!--<a mat-menu-item [matMenuTriggerFor]="Tema">{{idiomas.Tema}}</a>
                        <mat-menu #Tema="matMenu">
                            <a mat-menu-item [class.menuItemSelected]="temaSeleccionadoBand==0"
                                [class.menuItemNoSelected]="temaSeleccionadoBand!=0"
                                (click)=cambiarTema(0)>{{idiomas.TemaOscuro}}</a>
                            <a mat-menu-item [class.menuItemSelected]="temaSeleccionadoBand==1"
                                [class.menuItemNoSelected]="temaSeleccionadoBand!=1"
                                (click)=cambiarTema(1)>{{idiomas.TemaClaro}}</a>
                        </mat-menu>-->
                        <a mat-menu-item [matMenuTriggerFor]="Skin">Skin</a>
                        <mat-menu #Skin="matMenu">
                            <a mat-menu-item [class.menuItemSelected]="skinSeleccionadoBand==0"
                                [class.menuItemNoSelected]="skinSeleccionadoBand!=0" (click)=cambiarSkin(0)>Aestetic</a>
                            <a mat-menu-item [class.menuItemSelected]="skinSeleccionadoBand==1"
                                [class.menuItemNoSelected]="skinSeleccionadoBand!=1" (click)=cambiarSkin(1)>Compact</a>
                        </mat-menu>
                    </mat-menu>
                </mat-toolbar-row>
            </mat-toolbar>
        </div>
        <div class="under-toolbar">
            <mat-tab-group #matTabContentGroup class="tabs" [class.offline]=!internetC.internet [class.mediano]=mediano
                [class.grande]=grande (selectedIndexChange)="selectedIndexChange($event)"
                [selectedIndex]="selectedIndex">

                <!-- Espacio para componentes correspondientes a cada pestaña/tab agregado (Deben ser en el mismo orden) -->

                <mat-tab *ngIf="shownTransactionsTabs['PosicionConsolidada']" label="PosicionConsolidada">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-form-posicion-consolidada #posiConso [mediano]="mediano" [grande]="grande"
                            (operationEventHome)="move($event.transaction); operationEvent($event)" [normal]="normal"
                            [idiomas]="idiomas" [skinSeleccionadoBand]="skinSeleccionadoBand">
                        </app-form-posicion-consolidada>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['tarPend']" label="tarPend">
                    <div (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-tareas-pendientes [idiomas]="idiomas" #numTareas (numTarPen)="numTarPend($event)"
                            (tarPend)="toSpecificTab($event)"></app-tareas-pendientes>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['Beneficiarios']" label="Beneficiarios">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-beneficiarios #beneficiarios [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas" [account]="account" [pendingTaskData]="dataTarPend"
                            [bandTarjetaCredito]="1">
                        </app-beneficiarios>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['TransferenciasCuentasPropias']"
                    label="TransferenciasCuentasPropias">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-transferencias-cuentas-propias #transCtsProp [mediano]="mediano" [grande]="grande"
                            [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend">
                        </app-transferencias-cuentas-propias>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['TransferenciasTerceros']" label="TransferenciasTerceros">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-transferencias-misma-institutcion #transMismaInst [mediano]="mediano" [grande]="grande"
                            [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend">
                        </app-transferencias-misma-institutcion>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['TransferenciasAOtros']" label="TransferenciasAOtros">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-transferencias #transOtraInst [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas" [dataTarPend]="dataTarPend" [bandTarjetaCredito]="1">
                        </app-transferencias>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['TransferenciasAlExterior']" label="TransferenciasAlExterior">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-transferencias-exterior #transExterior [mediano]="mediano" [grande]="grande"
                            [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend">
                        </app-transferencias-exterior>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['CTransferencias']" label="CTransferencias">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-consulta-transferencias [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas" [bandTarjetaCredito]="1" [logoTemaOscuro]="logoTemaOscuro">
                        </app-consulta-transferencias>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['CTransferenciasAlExterior']" label="CTransferenciasAlExterior">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-consulta-transferencias-exterior [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas" [bandTarjetaCredito]="1" [logoTemaOscuro]="logoTemaOscuro">
                        </app-consulta-transferencias-exterior>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['TarjetasCredito']" label="TarjetasCredito">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-beneficiarios #beneficiarios [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas" [pendingTaskData]="dataTarPend" [bandTarjetaCredito]="2">
                        </app-beneficiarios>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['TransferenciasTarjetaCredito']"
                    label="TransferenciasTarjetaCredito">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-transferencia-tarjetas-credito #transTarCred [mediano]="mediano" [grande]="grande"
                            [normal]="normal" [idiomas]="idiomas" [dataTarPend]="dataTarPend" [bandTarjetaCredito]="2">
                        </app-transferencia-tarjetas-credito>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['CTransferenciasTarjeta']" label="CTransferenciasTarjeta">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-consulta-transferencias [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas" [bandTarjetaCredito]="2" [logoTemaOscuro]="logoTemaOscuro">
                        </app-consulta-transferencias>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['gestionServicios']" label="gestionServicios">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-consulta-servicios #servicios [dataTarPend]="dataTarPend" [mediano]="mediano"
                            [grande]="grande" [normal]="normal" [idiomas]="idiomas"></app-consulta-servicios>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['pagServ']" label="pagServ">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-pago-servicios-menu #pagoServicios [selectedIndex]=20 [mediano]="mediano" [grande]="grande"
                            [normal]="normal" [idiomas]="idiomas" [pendingTaskData]="dataTarPend"
                            [logoTemaOscuro]="logoTemaOscuro">
                        </app-pago-servicios-menu>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['Recargas']" label="Recargas">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-recargas #recargas [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas" [pendingTaskData]="dataTarPend"></app-recargas>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['pagRec']" label="pagRec">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-pago-recaudaciones #pagRec [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas" [pendingTaskData]="dataTarPend"></app-pago-recaudaciones>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['consPagServ']" label="consPagServ">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-consulta-pago-servicios #consultaPagServicios [mediano]="mediano" [grande]="grande"
                            [normal]="normal" [idiomas]="idiomas" [logoTemaOscuro]="logoTemaOscuro">
                        </app-consulta-pago-servicios>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['plazoFijo']" label="plazoFijo">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-solicitud-plazo #plazoFijo [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas" [logoTemaOscuro]="logoTemaOscuro">
                        </app-solicitud-plazo>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['CertificadoBancario']" label="CertificadoBancario">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-certificado-bancario #plazoFijo [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas" [logoTemaOscuro]="logoTemaOscuro">
                        </app-certificado-bancario>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['AhorrosProgramados']" label="AhorroProgramado">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-ahorro-programado #ahorrosProgramados [mediano]="mediano" [grande]="grande"
                            [normal]="normal" [idiomas]="idiomas"></app-ahorro-programado>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['Notificaciones']" label="Notificaciones">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-notificaciones #messageUser [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas"></app-notificaciones>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['MontoMaximoTransferencia']" label="MontoMaximoTransferencia">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-montos-transferencias [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas">
                        </app-montos-transferencias>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['Administracion']" label="Administracion">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-administracion [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas">
                        </app-administracion>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['SolicitudCredito']" label="SolicitudCredito">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-proceso-credito-online #modSolicitudCredito [mediano]="mediano" [grande]="grande"
                            [normal]="normal" [idiomas]="idiomas"></app-proceso-credito-online>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['PrestamoPreaprobadoT']" label="PrestamoPreaprobadoT">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-generar-carta-preaprobado [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas">
                        </app-generar-carta-preaprobado>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['PlazoFijo']" label="PlazoFijo">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-solicitud-plazo [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas">
                        </app-solicitud-plazo>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['BtnContactAsesor']" label="BtnContactAsesor">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-contactar-acesor [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas">
                        </app-contactar-acesor>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['btnCambiarPreg']" label="btnCambiarPreg">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-preguntas-frecuentes [mediano]="mediano" [grande]="grande" [normal]="normal"
                            [idiomas]="idiomas">
                        </app-preguntas-frecuentes>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['btnBloqueoDeCuenta']" label="btnBloqueoDeCuenta">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-bloqueo-cuenta [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas">
                        </app-bloqueo-cuenta>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="shownTransactionsTabs['btnCambiarCalif']" label="btnCambiarCalif">
                    <div class="contenido" (swipeleft)="right($event)" (swiperight)="izq($event)">
                        <app-calificanos [mediano]="mediano" [grande]="grande" [normal]="normal" [idiomas]="idiomas">
                        </app-calificanos>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<!-- Powered by FITBANK -->
<div class="row bottomBanner" style="position: fixed; bottom: 0;">
    <span *ngIf="logoTemaOscuro"
        style="font-size:13px;line-height:15px;color:#E3E2E2;vertical-align:middle;font-weight: bold;font-style: italic;"><strong></strong>Powered
        by&nbsp;</span>
    <span *ngIf="!logoTemaOscuro"
        style="font-size:13px;line-height:15px;color:#7C7E7E;vertical-align:middle;font-weight: bold;font-style: italic;"><strong></strong>Powered
        by&nbsp;</span>
    <span
        style="font-size:13px;line-height:15px;color:#0082cb;vertical-align:middle;font-weight: bold;"><strong></strong>
        FIT</span>
    <span
        style="font-size:13px;line-height:15px;color:#f7941d;vertical-align:middle;font-weight: bold;"><strong></strong>
        BANK&nbsp;</span>
    <span *ngIf="logoTemaOscuro"
        style="font-size:13px;line-height:15px;color:#E3E2E2;vertical-align:middle;font-weight: bold;"><strong></strong>
        &reg;</span>
    <span *ngIf="!logoTemaOscuro"
        style="font-size:13px;line-height:15px;color:#7C7E7E;vertical-align:middle;font-weight: bold;"><strong></strong>
        &reg;</span>
</div>

<!-- Powered by FITBANK -->
<!--<div *ngIf="bandMobile" class="float-menu" id="menu" style="margin-right: 4% !important;">
    <button mat-fab (click)="openBottomMenu()" title={{idiomas.Menu}} class="float-right" color="primary"
        style="margin-right:0px !important">
        <mat-icon>menu_open</mat-icon>
    </button>
</div>-->
<div class="bottomBannerMob" *ngIf="bandMobile">
    <div *ngIf=intento style="padding-top: 2px; padding-bottom: 2px;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <section class="policy-flechas-menu">
        <owl-carousel-o [options]="customCarouselOptions">
            <ng-container>
                <ng-template carouselSlide>
                    <div class="form-row" style="width: 100%;">
                        <div *ngIf="temaSeleccionado == 'light-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 0 ? '#fdc800' : '#2675A9'}"
                                (click)="onTabClick(0); move('PosicionConsolidada'); selectedSNav(true); closePanels();">donut_large</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 0 ? '#fdc800' : '#2675A9'}">{{idiomas.Home |
                                uppercase}}</p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'night-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 0 ? '#fdc800' : '#ffffff'}"
                                (click)="onTabClick(0); move('PosicionConsolidada'); selectedSNav(true); closePanels();">donut_large</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px"
                                [ngStyle]="{'color':tabIndex === 0 ? '#fdc800' : '#ffffff'}">{{idiomas.Home |
                                uppercase}}</p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'light-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 1 ? '#fdc800' : '#2675A9'}"
                                (click)="onTabClick(1); move('Beneficiarios'); selectedSNav(true); closePanels();">loop</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 1 ? '#fdc800' : '#2675A9'}">
                                {{idiomas.lblTransferenciasMob | uppercase}}</p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'night-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 1 ? '#fdc800' : '#ffffff'}"
                                (click)="onTabClick(1); move('Beneficiarios'); selectedSNav(true); closePanels();">loop</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 1 ? '#fdc800' : '#ffffff'}">
                                {{idiomas.lblTransferenciasMob | uppercase}}</p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'light-theme'" class="col-3"
                            style="text-align: center !important;">
                            <img class="icono-cash"
                                [src]="tabIndex === 2 ? 'assets/resources/mipmap-hdpi/icon-cash-amarillo.svg' : 'assets/resources/mipmap-hdpi/icon-cash-azul.svg'"
                                (click)="onTabClick(2); openCodeQR(); selectedSNav(true); closePanels();">
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 2 ? '#fdc800' : '#2675A9'}">CASH CASH</p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'night-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 2 ? '#fdc800' : '#ffffff'}"
                                (click)="onTabClick(2); openCodeQR(); selectedSNav(true); closePanels();">qr_code_scanner</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 2 ? '#fdc800' : '#ffffff'}">CASH CASH</p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'light-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 7 ? '#fdc800' : '#2675A9'}"
                                (click)="onTabClick(7); openBottomMenu(); selectedSNav(true); closePanels();"
                                matBadge="{{notificaciones}}" [matBadgeHidden]="notificaciones<=0">menu_open</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 7 ? '#fdc800' : '#2675A9'}">{{idiomas.Opciones |
                                uppercase}}
                            </p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'night-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 7 ? '#fdc800' : '#ffffff'}"
                                (click)="onTabClick(7); openBottomMenu(); selectedSNav(true); closePanels();"
                                matBadge="{{notificaciones}}" [matBadgeHidden]="notificaciones<=0">menu_open</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 7 ? '#fdc800' : '#ffffff'}">{{idiomas.Opciones |
                                uppercase}}
                            </p>
                        </div>
                    </div>
                </ng-template>
                <!-- <ng-template carouselSlide>
                    <div class="form-row" style="width: 100%;">
                        <div *ngIf="temaSeleccionado == 'light-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 4 ? '#fdc800' : '#2675A9'}"
                                (click)="onTabClick(4); move('Recargas'); selectedSNav(true); closePanels();">local_atm</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 4 ? '#fdc800' : '#2675A9'}">{{idiomas.Recargas |
                                uppercase}}
                            </p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'night-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 4 ? '#fdc800' : '#ffffff'}"
                                (click)="onTabClick(4); move('Recargas'); selectedSNav(true); closePanels();">local_atm</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 4 ? '#fdc800' : '#ffffff'}">{{idiomas.Recargas |
                                uppercase}}
                            </p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'light-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#2675A9'}"
                                (click)="onTabClick(5); move('plazoFijo'); selectedSNav(true); closePanels();">attach_money</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#2675A9'}">{{idiomas.InversionPC |
                                uppercase}}
                            </p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'night-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#ffffff'}"
                                (click)="onTabClick(5); move('plazoFijo'); selectedSNav(true); closePanels();">attach_money</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 5 ? '#fdc800' : '#ffffff'}">{{idiomas.InversionPC |
                                uppercase}}
                            </p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'light-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 6 ? '#fdc800' : '#2675A9'}"
                                (click)="onTabClick(6); move('TarjetasCredito'); selectedSNav(true); closePanels();">credit_card</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 6 ? '#fdc800' : '#2675A9'}">{{idiomas.TarjetasPc |
                                uppercase}}</p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'night-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 6 ? '#fdc800' : '#ffffff'}"
                                (click)="onTabClick(6); move('TarjetasCredito'); selectedSNav(true); closePanels();">credit_card</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 6 ? '#fdc800' : '#ffffff'}">{{idiomas.TarjetasPc |
                                uppercase}}</p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'light-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 7 ? '#fdc800' : '#2675A9'}"
                                (click)="onTabClick(7); openBottomMenu(); selectedSNav(true); closePanels();"
                                matBadge="{{notificaciones}}" [matBadgeHidden]="notificaciones<=0">menu_open</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 7 ? '#fdc800' : '#2675A9'}">{{idiomas.Opciones |
                                uppercase}}
                            </p>
                        </div>
                        <div *ngIf="temaSeleccionado == 'night-theme'" class="col-3"
                            style="text-align: center !important;">
                            <mat-icon class="bottom-menu-icons"
                                [ngStyle]="{'color':tabIndex === 7 ? '#fdc800' : '#ffffff'}"
                                (click)="onTabClick(7); openBottomMenu(); selectedSNav(true); closePanels();"
                                matBadge="{{notificaciones}}" [matBadgeHidden]="notificaciones<=0">menu_open</mat-icon>
                            <p style="font-size: .6em; margin-top: -5px;"
                                [ngStyle]="{'color':tabIndex === 7 ? '#fdc800' : '#ffffff'}">{{idiomas.Opciones |
                                uppercase}}
                            </p>
                        </div>
                    </div>
                </ng-template> -->
            </ng-container>
        </owl-carousel-o>
    </section>
</div>