import { Component, OnInit, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { ApiService } from '../api.service';
import { BaseDatos } from '../funciones/basededatos';
import { FormControlValidators } from '../funciones/formcontrol';
import { Alerta } from '../funciones/alerta';
import { internetComponent } from '../funciones/internet';
import { CantidadMonetariaPosCon, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { Servicios } from '../funciones/encryptar';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TemporizadorComponent } from '../temporizador/temporizador.component';
import { CurrencyInputDirective } from '../currency-input.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-bloqueo-cuenta',
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatButtonModule, MatDividerModule, MatInputModule, MatProgressBarModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans, CantidadMonetariaPosCon, CurrencyInputDirective, FlexLayoutModule, TemporizadorComponent, MatCheckboxModule],
  templateUrl: './bloqueo-cuenta.component.html',
  styleUrl: './bloqueo-cuenta.component.css'
})
export class BloqueoCuentaComponent implements OnInit {
 
  //Inputs
  @Input() idiomas: any;
  @Input() dataTarPend: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;

  //Variables
  monedaDesde: string;
  productoDesde: string;
  cuentas: any;
  cuentasAux: any;
  cuentasRecep: any;
  cuentasAuxRecep: any;
  intento: boolean;
  cuentasBeneficiarios: any;
  tokenSolicitado: boolean;
  comision: string;
  bandCtsDesde: boolean = false;
  bandCtsHacia: boolean = false;
  CodigoBackend: string = "";
  intentoCuentasOwn: boolean = false;
  intentoCuentasBene: boolean = false;
  pinValid: boolean = false;
  pinValidation: boolean;

  //Variable para el temporizador
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;
  fechaTransaccion: string = "";
  numeroMensaje: string = "";
  bandMobile: boolean;
  disableButton: boolean;
  intentoReenviar: boolean = false;

  //FormControls
  token = this.validators.otpForm();
  cuentaDesdeSelected = this.validators.comboForm();
  motivoDeBloqueoDeCuenta = this.validators.textNumberForm();
  transferenciaSelected = new UntypedFormControl();

  //CHECKBOX
  checkedAuthorizeAccountBlocking: boolean = false;

  constructor(public alerta: Alerta, public servicios: Servicios, public apiService: ApiService, public basedatos:
    BaseDatos, public validators: FormControlValidators, public internet: internetComponent) {
    this.pinValidation = this.basedatos.retornarConsulta("pinValidation")
    this.cuentas = [];
    this.cuentasAux = [];
    this.cuentasRecep = [];
    this.cuentasAuxRecep = [];
    this.comision = "";
    this.enableCronoIn = false;
    this.mostrarCron = false;
    this.disableButton = false;
    this.intentoReenviar = false;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  ngOnInit() {
    this.funcionRetornarCuentasOwn("e");
    this.token.setValue('');
    this.obtainBeneficiaryAccounts();
    this.tokenSolicitado = false;
    this.intento = false;
    this.intentoReenviar = false;
  }

  ngAfterViewInit() {
    //Data desde tareas pendientes
    if (this.dataTarPend) {
      Promise.resolve(null).then(() => this.intento = true);
      setTimeout(function () { Promise.resolve(null).then(() => this.intentoReenviar = true), 100 });
      Promise.resolve(null).then(() => this.funcionRetornarCuentasOwn("e"));
    }
    //.
  }

  setValuesOfForm() {
    this.cuentaDesdeSelected.setValue(this.dataTarPend[0]);
  }

  authorizeAccountBlocking(event) {
    if (event.checked == true) {
      this.checkedAuthorizeAccountBlocking = true;
    } else {
      this.checkedAuthorizeAccountBlocking = false;
    }
  }

  obtainOwnAccount() {
    this.intentoCuentasOwn = false;
    const envio = {
      'transaccion': '186000-lv-cuentas-in.xml',
      'fullNames': 'true',
      'cri^tc0^CESTATUSCUENTA^JOIN': '002',
      'cri^tc0^CSUBSISTEMA^JOIN': '04',
      'cri^tu0^CUSUARIO^JOIN^tc0^=': '$userCode',
      'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN^tc0^=': '18',
      'cri^tctp0^CTRANSACCION^JOIN^tc0^=': '6073',
      'cri^tctp0^VERSIONTRANSACCION^JOIN^tc0^=': '01'
    };
    this.apiService.postProvider2InternetCheck('/consulta', this.basedatos.id_token, envio).then((data: any) => {
      if (data.tc0) {
        this.cuentas = this.transformadorCuentasDesde(data.tc0);
        this.cuentasAux = this.transformadorCuentasDesde(data.tc0);
        // Data desde tareas pendientes
        this.dataTarPend = this.cuentas;
        if (this.dataTarPend) {
          Promise.resolve(null).then(() => this.setValuesOfForm());
        }
        // .
        // Almacenar Consulta
        const consulta = {
          'fecha': new Date().toLocaleDateString(),
          'hora': new Date().toLocaleTimeString(),
          'data': this.cuentas,
        };
        this.basedatos.insertarConsulta('CtasTransfCtasProp', consulta);
        this.bandCtsDesde = true;
        this.intentoCuentasOwn = true;
      }
    }, (err) => {
      this.intentoCuentasOwn = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet    
        this.cuentasTransferOffline();
        if (!this.cuentas || !this.cuentasAux) {
          this.cuentas = [];
          this.cuentasAux = [];
        }
      }
    });
  }

  funcionRetornarCuentasOwn(e) {
    if (!this.intentoCuentasOwn) {
      this.obtainOwnAccount();
    }
  }


  cuentasTransferOffline() {
    const ObjConsulta = this.basedatos.retornarConsulta('CtasTransfCtasProp');
    if (!ObjConsulta || ObjConsulta.data === 0) {
      this.alerta.presentarAlerta(this.idiomas.FaltaConsultaCuentasTransferencias);
      this.close();
    } else {
      this.cuentas = [];
      this.cuentasAux = [];
      if (!this.dataTarPend) {
        this.alerta.generarOfflineDialogo(ObjConsulta, this.idiomas);
      }
      this.cuentas = ObjConsulta.data;
      this.cuentasAux = ObjConsulta.data;
      this.bandCtsDesde = true;
    }
  }

 
  logout() {
    let obj = {
      'salir': true
    };
    this.alerta.clearAlerta();
    this.alerta.generarOfflineDialogo(obj, this.idiomas);
  }

  obtainBeneficiaryAccounts() {
    this.cuentasBeneficiarios = [];
    if (this.cuentaDesdeSelected.value) {
      for (let i = 0; i < this.cuentasRecep.length; i++) {
        if (this.cuentasRecep[i].value != this.cuentaDesdeSelected.value.value) {
          this.cuentasBeneficiarios.push(this.cuentasRecep[i]);
        }
      }
    } else {
      this.cuentasBeneficiarios = this.cuentasRecep;
    }
    if (this.dataTarPend) {
      this.cuentasAuxRecep = this.cuentasBeneficiarios;
    }
  }

  transformadorCuentasDesde(value) {
    const lista = [];
    for (let i = 0; i < value.length; i++) {
      lista.push({
        value: value[i]["tc0.CCUENTA"], viewValue:
          value[i]["tc0.CCUENTA"], 'descProducto': value[i]["tp0.DESCRIPCION"], moneda: value[i]["tc0.CMONEDA"]
        , saldo: value[i]["tsd0.SALDOMONEDAOFICIAL"] == "null" ? "0.00" : value[i]["tsd0.SALDOMONEDAOFICIAL"], SDisponible: this.idiomas.SDisponible
      });
    }
    return lista;
  }

  seleccionarCuentaDesde() {
    this.obtainBeneficiaryAccounts();
    this.monedaDesde = this.cuentaDesdeSelected.value.moneda;
    this.productoDesde = this.cuentaDesdeSelected.value.descProducto;
  }

  solicitarToken() {
    this.intento = true;
    const envio = { transaccion: '046047' };
    this.apiService.postProvider2InternetCheck('/clavetemporal', this.basedatos.id_token, envio).then((data: any) => {
      this.tokenSolicitado = true;
      this.intento = false;
      this.token.reset();
      this.alerta.presentarAlerta(this.idiomas.EnvioToken.toUpperCase());
      setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alerta.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  sendAccountBlocking(isValidation){
    let envio = {
      'cusuario': "$userCode",
      'ccuenta': this.cuentaDesdeSelected.value.value,
      'observacionOperativa': this.motivoDeBloqueoDeCuenta.value,
      'clave': this.servicios.toAES(!this.pinValidation ? this.token.value : "000000"),
      'desencriptar': "1",
      'isMobile': this.bandMobile ? "1" : "0",
      'isValidation': isValidation,
    }

    if (isValidation == '1') {
        this.apiService.postProvider2InternetCheck('/bloqueoCuenta', this.basedatos.id_token, envio).then((dataTr: any) => {
                
        this.disableFormControls();
        this.intento = false;
        this.numeroMensaje = dataTr.numeroMensaje;
        this.enableCronoIn = false;
        this.mostrarCron = false;
        this.alerta.generarDialogoSeguroConfirmacion(this.idiomas, true, false);
        }, (err) => {
            this.intento = false;
            this.disableButton = this.pinValidation ? true : false;
            this.enableCronoIn = false;
            this.mostrarCron = false;
              if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
                if (err.error) {
                  if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
                  else if (err.error.mensaje == "MENSAJE PENDIENTE DE AUTORIZACION POR SOFT TOKEN ") {
                    if (this.pinValidation) {
                      let user = {
                        userCode: this.basedatos.usuarioHB
                      }
                      this.alerta.generarDialogoSolicitarAutorizacionData(this.idiomas, this.grande, this.mediano, user, "").then((data: any) => {
                        if (data) {
                          this.pinValid = true
                          this.disableButton = false
                          if (this.bandMobile) {
                            this.sendAccountBlocking('0')
                          }
                        } else {
                          this.intento = false
                          this.pinValid = false
                          this.disableButton = false
                        }
                      }, (err) => {
                        this.intento = false
                      });
                    }
                  } else {
                    this.alerta.generarDialogoSeguroErrortransacciones(this.idiomas, err.error.mensajeUsuario);
                  }
                } else {
                  this.alerta.generarDialogoSeguroErrortransacciones(this.idiomas, this.idiomas.ServidorError);
                }
              } else { // Sin Internet
                this.disableFormControls();
                this.basedatos.insertarTarea(envio);
                this.alerta.presentarAlerta(this.idiomas.NoConectado);
              }
          });           
    }else{
        this.apiService.postProvider2InternetCheck('/bloqueoCuenta', this.basedatos.id_token, envio).then((dataTr: any) => {
          console.log('dataTar',dataTr)
          this.disableFormControls();
          this.intento = false;
          //this.CodigoBackend = dataTr.CodigoBackend;
          //this.fechaTransaccion = dataTr.fechaTransaccion;
          this.numeroMensaje = dataTr.numeroMensaje;
          this.enableCronoIn = false;
          this.mostrarCron = false;
            this.alerta.generarDialogoBloqueoCuenta(this.idiomas, 'DialogoParaEnviarNotificacionAlCorreo', true, false);
        }, (err) => {
          this.disableFormControls();
          this.intento = false;
          this.disableButton = false;
          this.enableCronoIn = false;
          this.mostrarCron = false;
          if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
            if (err.error) {
              if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
              else {
                this.intento=false;
                this.alerta.generarDialogoSeguroErrortransacciones(this.idiomas, err.error.mensajeUsuario);
              }
            } else {
              this.intento=false;
              this.alerta.generarDialogoSeguroErrortransacciones(this.idiomas, this.idiomas.ServidorError);
            }
          } else { // Sin Internet
            this.disableFormControls();
            this.basedatos.insertarTarea(envio);
            this.alerta.presentarAlerta(this.idiomas.NoConectado);
          }
        });
      }
  }

  disableFormControls() {
    this.cuentaDesdeSelected.disable();
    this.motivoDeBloqueoDeCuenta.disable();
    this.token.disable();
  }


  close() {
    this.intento = false;
    this.mostrarCron = false;
    this.enableCronoIn = false;
    this.tokenSolicitado = false;
    this.disableButton = false;
    this.pinValid = false;
    this.transferenciaSelected.setValue('');
    this.cuentaDesdeSelected.setValue('');
    this.motivoDeBloqueoDeCuenta.setValue('');
    this.token.setValue('');
    this.seleccionarCuentaDesde();
    this.motivoDeBloqueoDeCuenta.reset();
    this.cuentaDesdeSelected.reset();
    this.token.reset();
    this.motivoDeBloqueoDeCuenta.enable();
    this.cuentaDesdeSelected.enable();
    this.token.enable();
    this.intentoReenviar = false;
    this.checkedAuthorizeAccountBlocking = false;
  }

  compareJsonPerValue(object1: any, object2: any) {
    return object1 && object2 && ((object1.value == object2.value) || (object1.viewValue.slice(-4) == object2.toString().slice(-4)));
  }

  selectedOptionOrigen(cta) {
    if (this.dataTarPend && this.dataTarPend.resender) {
      this.dataTarPend.cuentaOrigen = cta;
      this.dataTarPend.motivoDeBloqueoDeCuenta = '';
      Promise.resolve(null).then(() => this.setValuesOfForm());
    }
  }

  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }

}
