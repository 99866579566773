import { Component, OnInit, Input, AfterViewInit, HostListener } from '@angular/core';
import { UntypedFormControl, Validators, ValidationErrors, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ApiService } from '../api.service';
import { Alerta } from '../funciones/alerta';
import { Servicios } from '../funciones/encryptar';
import { BaseDatos } from '../funciones/basededatos';
import { FormControlValidators } from '../funciones/formcontrol';
import { internetComponent } from '../funciones/internet';
import { MostrarPDF } from '../funciones/mostrarPDF';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans } from '../pipes/custom-pipes.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { TemporizadorComponent } from '../temporizador/temporizador.component';

const AccountsListValueObjectName: String = 'CuentPropRecargas';
const ServiceListValueObjectName: String = 'ServicRecargas';

interface IRechargeValueObject {
  messageId: String;
  CUENTACREDITO: string;
  IDENTIFICACION_BENEFICIARIO: string;
}

interface IError {
  error: {
    mensaje: string;
  };
  status: number;
}

interface IReportValueObject {
  reporte: string;
}

const accountsWSObject = {
  'transaccion': '186000-lv-cuentas-in.xml',
  'usuario': '',
  'alias': 'tc0',
  'cri^tc0^CESTATUSCUENTA^JOIN': '002',
  'cri^tc0^CSUBSISTEMA^JOIN': '04',
  'cri^tu0^CUSUARIO^JOIN': '',
  'cri^tctp0^CSUBSISTEMA_TRANSACCION^JOIN': '18',
  'cri^tctp0^CTRANSACCION^JOIN': '6001',
  'cri^tctp0^VERSIONTRANSACCION^JOIN': '01'
};

const servicesWSObject = {
  'transaccion': '186001-lv-in.xml',
  'usuario': '',
  'alias': 'tpsc0',
  'cri^tpsc0^CPERSONA_COMPANIA^JOIN': '$company',
  'cri^tu0^CUSUARIO^JOIN': '$userCode',
};

const rechargeWSObject = {
  'numeroServicio': '',
  'codigoServicio': '',
  'comentarioServicio': '',
  'usuario': '',
  'cuenta': '',
  'monto': '',
  'clave': '',
  'comision': '',
  'moneda': '',
  'tarPendType': 'recargas'
};

const reportWSObject = {
  'usuario': '',
  'nombre': '18_COMPROBANTE_PAGO',
  'subsistema': '18',
  'transaccion': '6000',
  'tipo': 'PDF',
  'rep^DESCRIPCION_TRANSACCION': 'RECARGAS MÓVILES',
  'rep^NUMEROMENSAJE': ''
};

@Component({
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatButtonModule, MatDividerModule, MatProgressBarModule, MatInputModule, CommonModule, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDatepickerModule, CantidadMonetaria, FormatoFechaCalendar, OcultarCuentaPosTrans, FlexLayoutModule, TemporizadorComponent],
  selector: 'app-recargas',
  templateUrl: './recargas.component.html',
  styleUrls: ['./recargas.component.css']
})
export class RecargasComponent implements OnInit, AfterViewInit {

  //Input
  @Input() idiomas: any;
  @Input() grande: boolean;
  @Input() mediano: boolean;
  @Input() normal: boolean;
  @Input() pendingTaskData: any;
  @Input() resendPay: any;

  //Variables
  selectedValue: string = '';
  amountsClaro: any[] = [
    { combo: 'CLARO MEGAS $1.05', value: '1.05' },
    { combo: 'CLARO MEGAS $2.05', value: '2.05' },
    { combo: 'CLARO GIGAS $3.10', value: '3.10' },
    { combo: 'CLARO GIGAS $4.10', value: '4.10' },
    { combo: 'CLARO GIGAS $5.15', value: '5.15' },
    { combo: 'CLARO GIGAS $7.20', value: '7.20' },
    { combo: 'CLARO GIGAS $10.30', value: '10.25' },
    { combo: 'CLARO GIGAS $12.30', value: '12.30' },
    { combo: 'CLARO GIGAS $15.50', value: '15.50' },
    { combo: 'CLARO GIGAS $20.50', value: '20.50' }
  ];
  references: any[];
  accounts: any[];
  selectedReference: any;
  selectedAccount: any;
  tokenRequested = false;
  fee: string;
  intento: boolean;
  rechargeExecuted = false;
  pdfBase64: string;
  accountforCredit: string;
  idBeneficiary: string;
  dowloadReporte: boolean;
  bandDisableForm: boolean;
  repInvoiceData: string = "";
  repInvoiceAdditionalData: string = "";
  amountValuesArray: any[] = null;
  minValueAmount: string = "";
  maxValueAmount: string = "";
  stepValueAmount: string = "";
  usuarioSospechoso: boolean;
  intentoUsuarioSospechoso: boolean;
  intentoCuentasOwn: boolean = false;
  enableCronoIn: boolean = false;
  mostrarCron: boolean = false;
  bandMobile: boolean = false;
  fechaTransaccion: string = "";
  numeroMensaje: string = "";
  nomServ: any;
  disableButton: boolean;

  //FormControls
  referenceFormControl = this.validators.numberForm();
  rechargeFormControl = this.validators.decimalsForm();
  accountFormControl = this.validators.comboForm();
  descriptionFormControl = this.validators.textNumberForm();
  tokenFormControl = this.validators.otpForm();
  suministroForm = this.validators.textNumberForm();

  constructor(private api: ApiService, public alert: Alerta, private service: Servicios,
    private dbHandler: BaseDatos, public internet: internetComponent, public validators: FormControlValidators,
    public mostrarPdf: MostrarPDF) {
    this.references = [];
    this.accounts = [];
    this.fee = '0.00';
    this.intento = true;
    this.dowloadReporte = false;
    this.bandDisableForm = false;
    this.nomServ = "RECARGAS";
    this.enableCronoIn = false;
    this.mostrarCron = false;
    this.bandMobile = false;
    this.disableButton = false;
  }

  ngOnInit() {
    this.bandMobile = false;
    this.usuarioSospechoso = true;
    this.intentoUsuarioSospechoso = false;
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /mobile|ipad|iphone|ipod|android/.test(userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    this.bandMobile = isMobile();
  }

  ngAfterViewInit() {
    this.getAccountList();
    this.retornarServicios();
    if (this.pendingTaskData) {
      this.setNewValuesFromPendingTask();
    }
  }

  setValuesOfForm() {
    this.referenceFormControl.setValue(this.resendPay.numeroServicio);
    this.onSelectedReference(this.resendPay.numeroServicio);
  }

  onSelectionChange(event: MatSelectChange): void {
    this.selectedValue = event.value;
  }

  consultarActividadesSospechosas() {
    let envioUsuario = {};
    this.api.postProvider2InternetCheck('/actividadSospechosa', this.dbHandler.id_token, envioUsuario).then((data: any) => {
      if (data[0]['SUSPICIUSACTIVITY'] == "true") {
        this.usuarioSospechoso = true;
        this.alert.generarDialogo(this.idiomas, "usuarioSospechoso")
      } else {
        this.usuarioSospechoso = false;
      }
      let consulta = {
        'fecha': new Date().toLocaleDateString(),
        'hora': new Date().toLocaleTimeString(),
        'data': data,
      };
      this.dbHandler.insertarConsulta('usuarioSospechoso', consulta); //Almacena en BDD los beneficiarios registrados
      this.intentoUsuarioSospechoso = true;
      //.
    }, (err) => {
      this.intentoUsuarioSospechoso = true;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout() }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else {
        this.usuarioSospechosoRegOffline();
      }
    });
  }

  usuarioSospechosoRegOffline() {
    let ObjConsulta = this.dbHandler.retornarConsulta('usuarioSospechoso');
    if (ObjConsulta.data[0]['SUSPICIUSACTIVITY'] == "true") {
      this.usuarioSospechoso = true;
      this.alert.generarDialogo(this.idiomas, "usuarioSospechoso")
    } else {
      this.usuarioSospechoso = false;
      this.intentoUsuarioSospechoso = true;
    }
  }

  getCurrentTimeAsTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = this.completeDigits(now.getMonth() + 1);
    const day = this.completeDigits(now.getDate());
    return `${year}-${month}-${day} 00:00:00.000`;
  }

  createPDFReport() {
    var fecha = new Date();
    var fd = fecha.getFullYear() + "-" + this.anadirCero(fecha.getMonth() + 1) + "-" + this.anadirCero(fecha.getDate()) + " " + fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds();
    this.intento = true;
    reportWSObject['rep^NUMEROMENSAJE'] = this.numeroMensaje;
    this.api.postProvider2InternetCheck("/reporte", this.dbHandler.id_token, reportWSObject).then((data: any) => {
      if (data) {
        this.intento = false;
        this.pdfBase64 = data.reporte;
        this.mostrarPdf.mostrarReportePDF(this.pdfBase64);
      }
    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
  }

  completeDigits(datePart: number): string {
    return datePart <= 9
      ? '0'.concat(datePart.toString())
      : datePart.toString();
  }

  setNewValuesFromPendingTask() {
    this.selectedReference = this.references.find((suministro) => (suministro.NUMEROCONTRATO === this.pendingTaskData.numeroServicio));
    this.referenceFormControl.setValue(this.pendingTaskData.numeroServicio);
    this.rechargeFormControl.setValue(this.validators.FormatNumber(this.pendingTaskData.monto));
    this.selectedAccount = this.accounts.find((accountFromList) => (accountFromList.CCUENTA === this.pendingTaskData.cuenta));
    this.accountFormControl.setValue(this.pendingTaskData.cuenta);
    this.fee = this.validators.FormatNumber(this.pendingTaskData.comision);
    this.descriptionFormControl.setValue(this.pendingTaskData.descripcion);
  }

  getAccountList() {
    this.intentoCuentasOwn = false;
    accountsWSObject['cri^tu0^CUSUARIO^JOIN'] = "$userCode";
    this.api.postProvider2InternetCheck('/listadevalores', this.dbHandler.id_token, accountsWSObject).then((data: any) => {
      if (data) {
        this.accounts = data;
        this.dbHandler.insertarConsulta(AccountsListValueObjectName, this.accounts);
        this.intentoCuentasOwn = true;
        this.accountFormControl.setValue(this.accounts[0].CCUENTA);
        this.onSelectedAccount(this.accounts[0].CCUENTA);
      }
    }, (err) => {
      this.intentoCuentasOwn = false;
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alert.presentarAlertaX(err.error.mensajeUsuario);
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet    
        this.accounts = this.dbHandler.retornarConsulta(AccountsListValueObjectName);
        if (!this.accounts) {
          this.accounts = [];
        }
      }
    });
  }

  funcionRetornarCuentasOwn(e) {
    if (!this.intentoCuentasOwn) {
      this.getAccountList();
    }
  }

  getServicesList() {
    this.intento = true;
    this.api.postProvider2InternetCheck('/listadevalores', this.dbHandler.id_token, servicesWSObject).then((data: any) => {
      if (data) {
        this.references = data;
        this.intento = false;
        this.dbHandler.insertarConsulta(ServiceListValueObjectName, this.references);
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.alert.presentarAlertaX(err.error.mensajeUsuario);
        this.intento = false;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet    
        this.intento = false;
        this.references = this.dbHandler.retornarConsulta(ServiceListValueObjectName);
        if (!this.references) {
          this.references = [];
        }
      }
    });
  }

  makeRecharge() {
    this.numeroMensaje = "";
    this.fechaTransaccion = "";
    this.intento = true;
    this.disableButton = true;
    rechargeWSObject['numeroServicio'] = this.suministroForm.value;
    rechargeWSObject['codigoServicio'] = this.selectedReference.CSERVICIO;
    rechargeWSObject['comentarioServicio'] = this.selectedReference.DESCRIPCION;
    rechargeWSObject['usuario'] = "$userCode";
    rechargeWSObject['recarga'] = '1';
    rechargeWSObject['cuenta'] = this.selectedAccount.CCUENTA;
    rechargeWSObject['moneda'] = this.selectedAccount.CMONEDA;
    rechargeWSObject['monto'] = this.validators.FormatNumber(this.rechargeFormControl.value.toString()).replace(/,/g, '');
    rechargeWSObject['descripcion'] = this.descriptionFormControl.value.toUpperCase();
    rechargeWSObject['clave'] =
      (this.internet.internet && this.tokenFormControl.value)
        ? this.service.toAES(this.tokenFormControl.value)
        : '';
    rechargeWSObject['desencriptar'] = '1';
    rechargeWSObject['comision'] = this.validators.FormatNumber(this.fee).replace(/,/g, '');
    this.alert.generarDialogoConfirmacionTransferencias(this.idiomas.Recargas, 'Recargas', this.idiomas, rechargeWSObject).then((data) => {
      if (data) {
        this.api.postProvider2InternetCheck('/pagoservicios', this.dbHandler.id_token, rechargeWSObject).then((dataTr: any) => {
          this.disableFormControls();
          this.intento = false;
          this.rechargeExecuted = true;
          this.repInvoiceData = dataTr.invoiceData;
          this.repInvoiceAdditionalData = dataTr.invoiceAdditionalData;
          this.fechaTransaccion = dataTr.fechaTransaccion;
          this.numeroMensaje = dataTr.numeroMensaje;
          this.mostrarCron = false;
          this.enableCronoIn = false;
          this.alert.generarDialogoSegurotransacciones(this.idiomas, this.numeroMensaje, false, 6)
        }, (err) => {
          this.intento = false;
          this.dowloadReporte = false;
          this.mostrarCron = false;
          this.enableCronoIn = false;
          this.disableButton = false;
          if (err.status !== 0 && err.status !== 504 && err.status !== -1) {  // Con Internet
            this.rechargeExecuted = false;
            if (err.error) {
              if (err.error.mensaje === 'Error de autenticación via token JWT.') { this.logout(); }
              else {
                this.alert.generarDialogoSeguroErrortransacciones(this.idiomas, err.error.mensajeUsuario);
              }
            } else {
              this.alert.generarDialogoSeguroErrortransacciones(this.idiomas, this.idiomas.ServidorError);
            }
          } else { // Sin Internet
            this.disableFormControls();
            this.rechargeExecuted = false;
            let tareaPendienteJson = JSON.parse(JSON.stringify(rechargeWSObject));
            this.dbHandler.insertarTarea(tareaPendienteJson);
            this.alert.presentarAlerta(this.idiomas.NoConectado);
          }
        });
      } else {
        this.intento = false;
        this.rechargeExecuted = false;
        this.disableButton = false;
      }
    });
  }

  disableFormControls() {
    this.bandDisableForm = true;
    this.referenceFormControl.disable();
    this.suministroForm.disable();
    this.accountFormControl.disable();
    this.descriptionFormControl.disable();
    this.tokenFormControl.disable();
    this.rechargeFormControl.disable();
  }

  onSelectedReferenceRegRech(reference: string) {
    this.selectedReference = this.references.find((suministro) => (suministro.NUMEROCONTRATO === reference));
    if (!this.internet.internet) {
      this.alert.presentarAlerta(this.idiomas.ConsultaOffLinePagoServicio);
    }
  }

  onSelectedReference(cservicio: string) {
    this.resetCampos()
    this.selectedReference = this.references.find((suministro) => (suministro.CSERVICIO === cservicio));
    this.rechargeFormControl = this.validators.decimalsForm();
    this.amountValuesArray = null;
    this.minValueAmount = "";
    this.maxValueAmount = "";
    this.stepValueAmount = "";
    if (this.selectedReference.COMENTARIOS && this.selectedReference.COMENTARIOS != "null") {
      //Si existen restricciones de montos en recargas
      let restriccionesMontosString = this.selectedReference.COMENTARIOS.replace(/ /g, "");
      let restriccionesMontos = restriccionesMontosString.split("|");
      switch (restriccionesMontos[0]) {
        case "CB": //Valores Definidos de recarga Ej: 3,6,9
          this.amountValuesArray = [];
          restriccionesMontos[1].split(",").map(x => {
            let amountValue = { value: x, viewValue: new CantidadMonetaria().transform(x) };
            this.amountValuesArray.push(amountValue);
            return true;
          })
          if (this.amountValuesArray.length == 0) {
            this.amountValuesArray = null;
          }
          break;
        case "MMI": //Control con valores Minimo, Maximo e Intervalo Ej: 3,100,1 (min,max,interv)
          let valuesMMI = restriccionesMontos[1].split(",");
          this.minValueAmount = new CantidadMonetaria().transform(valuesMMI[0]);
          this.maxValueAmount = new CantidadMonetaria().transform(valuesMMI[1]);
          this.stepValueAmount = new CantidadMonetaria().transform(valuesMMI[2]);
          this.rechargeFormControl = new UntypedFormControl(null, [Validators.required, Validators.pattern('^[,0-9]+([.][0-9]{0,2})?$'), minAmountValidationNumber(valuesMMI[0]), maxAmountValidationNumber(valuesMMI[1]), noDecimalAmountValidation, nMultipleAmountValidationNumber(valuesMMI[0], valuesMMI[2])]);
          break;
        case "MI": //Control con valores Minimo e Intervalo Ej: 3,1 (min,interv)
          let valuesMI = restriccionesMontos[1].split(",");
          this.minValueAmount = new CantidadMonetaria().transform(valuesMI[0]);
          this.stepValueAmount = new CantidadMonetaria().transform(valuesMI[1]);
          this.rechargeFormControl = new UntypedFormControl(null, [Validators.required, Validators.pattern('^[,0-9]+([.][0-9]{0,2})?$'), minAmountValidationNumber(valuesMI[0]), noDecimalAmountValidation, nMultipleAmountValidationNumber(valuesMI[0], valuesMI[1])]);
          break;
        default:
          break;
      }
      if (this.resendPay) {
        this.suministroForm.setValue(this.resendPay.suministro);
      }
    }
  }

  onSelectedAccount(account: string) {
    this.selectedAccount = this.accounts.find((accountFromList) => (accountFromList.CCUENTA === account));
  }

  async clearScreenWithDialog() {
    const data = await this.alert.generarDialogoSeguro(this.idiomas);
    if (data) {
      this.reset();
    }
  }

  reset() {
    this.enableCronoIn = false;
    this.mostrarCron = false;
    this.bandDisableForm = false;
    this.selectedReference = null;
    this.tokenRequested = false;
    this.disableButton = false;
    this.rechargeExecuted = false;
    this.pdfBase64 = '';
    this.accountforCredit = '';
    this.idBeneficiary = '';
    this.referenceFormControl.enable();
    this.suministroForm.enable();
    this.rechargeFormControl.enable();
    this.descriptionFormControl.enable();
    this.tokenFormControl.enable();
    this.referenceFormControl.reset();
    this.suministroForm.reset();
    this.referenceFormControl.setErrors(null);
    this.suministroForm.setErrors(null);
    this.selectedReference = null;
    this.rechargeFormControl.reset();
    this.descriptionFormControl.reset();
    this.tokenFormControl.reset();
    document.querySelector('.mat-sidenav-content').scrollTop = 0;;
  }

  resetCampos() {
    this.bandDisableForm = false;
    this.selectedReference = null;
    this.tokenRequested = false;
    this.rechargeExecuted = false;
    this.pdfBase64 = '';
    this.accountforCredit = '';
    this.idBeneficiary = '';
    this.suministroForm.reset();
    this.referenceFormControl.setErrors(null);
    this.suministroForm.setErrors(null);
    this.selectedReference = null;
    this.rechargeFormControl.reset();
    this.descriptionFormControl.reset();
    this.tokenFormControl.reset();
    document.querySelector('.mat-sidenav-content').scrollTop = 0;;
  }

  logout() {
    this.alert.clearAlerta();
    this.alert.generarOfflineDialogo({ 'salir': true }, this.idiomas);
  }

  retornarServicios() { //Servicios Disponibles para Registrar
    let envio = {
      transaccion: "010003-lv-servicios-por-tipo-in.xml",
      alias: "tpsc0",
      "cri^tpsc0^NOMBRESCONTRATO^NORMAL": this.nomServ
    };
    this.api.postProvider2InternetCheck('/listadevalores', this.dbHandler.id_token, envio).then((data: any) => {
      if (data) {
        this.references = data;
        this.intento = false;
        if (this.resendPay) {
          Promise.resolve(null).then(() => this.setValuesOfForm());
        }
        this.dbHandler.insertarConsulta('Servicios' + this.nomServ + 'Disponibles', this.references); //Inserta Lista de Servicios Disponibles
      }
    }, (err) => {
      if (err.status != 0 && err.status != 504 && err.status != -1) {  //Con Internet
        this.intento = false;
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
      else { //Sin Internet    
        this.intento = false;
        this.references = this.dbHandler.retornarConsulta('Servicios' + this.nomServ + 'Disponibles');
        if (!this.references) {
          this.references = [];
        }
      }
    });
  }

  solicitarToken() {
    const envio = { transaccion: '186001' };
    this.intento = true;
    this.api.postProvider2InternetCheck('/clavetemporal', this.dbHandler.id_token, envio).then((data: any) => {
      this.tokenRequested = true;
      this.intento = false;
      this.tokenFormControl.reset();
      this.alert.presentarAlerta(this.idiomas.EnvioToken.toUpperCase());
      setTimeout(function () { document.getElementById("soliToken").focus(); }, 1);

    }, (err) => {
      this.intento = false;
      if (err.status != 0 && err.status != 504 && err.status != -1 && err.status != -1) {  //Con Internet
        if (err.error) {
          if (err.error.mensaje == "Error de autenticación via token JWT.") { this.logout(); }
        }
        else {
          this.alert.presentarAlerta(this.idiomas.ServidorError);
        }
      }
    });
    //iniciar el temporizador
    this.mostrarCron = true;
    this.enableCronoIn = true;
  }

  onTerminaCrono($event: any) {
    this.enableCronoIn = $event;
  }

  amountKeyInputControl(event) {
    if (!isNaN(Number(event.key)) || event.key === ',' || event.key === '.') {
      let amountArrayDotSplit = event.target.value.replace(/,/g, '').split(".");
      if (amountArrayDotSplit.length > 1) {
        if (event.key === ',' || event.key === '.') {
          event.preventDefault();
        }
        else {
          let intPart = amountArrayDotSplit[0];
          let decimalPart = amountArrayDotSplit[1];
          if (decimalPart.length > 1 && event.target.selectionStart >= intPart.length + 1) {
            event.preventDefault();
          }
        }
      } else {
        if (event.key === ',') {
          event.target.value += '.';
          event.preventDefault();
        }
      }
    }
    else {
      event.preventDefault();
    }
  }

  caretInputAmount(event) {
    event.target.value = event.target.value.replace(/,/g, '');
    let amountArrayDotSplit = event.target.value.split(".");
    if (amountArrayDotSplit.length > 1) {
      event.target.selectionStart = amountArrayDotSplit[0].length;
      event.target.selectionEnd = amountArrayDotSplit[0].length;
    }
    else {
      event.target.selectionStart = event.target.value.length;
      event.target.selectionEnd = event.target.value.length;
    }
  }

  anadirCero(numero: any) {
    let completarFecha = '';
    if (numero < 10) {
      completarFecha = '0' + numero;
    } else {
      completarFecha = numero;
    }
    return completarFecha;
  }
}

//Validacion de numero enteros como montos de recarga
function noDecimalAmountValidation(control: UntypedFormControl) {
  var monto = control.value;
  monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
  if (Number.isInteger(monto)) {
    return null;
  }
  else {
    return {
      noDecimalAmount: {
        parsedDomain: monto
      }
    }
  }
}

//Validacion de montos de recarga por intervalos
interface nMultipleAmountValidation {
  (control: UntypedFormControl): ValidationErrors | null;
}

function nMultipleAmountValidationNumber(minValue, stepValue): nMultipleAmountValidation {
  return (control: UntypedFormControl): ValidationErrors | null => {
    if (stepValue == 1) {
      return null;
    }
    var monto = control.value;
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    let montoOperation = monto;
    let errValidation = true;
    while (montoOperation >= minValue) {
      if (montoOperation == minValue) {
        errValidation = false;
        break;
      }
      montoOperation = montoOperation - stepValue;
    }
    if (!errValidation) {
      return null;
    } else {
      return {
        nMultipleAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de montos minimos de recarga
interface minAmountValidation {
  (control: UntypedFormControl): ValidationErrors | null;
}

function minAmountValidationNumber(minValue): minAmountValidation {
  return (control: UntypedFormControl): ValidationErrors | null => {
    var monto = control.value;
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    if (monto >= minValue) {
      return null;
    } else {
      return {
        minAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}

//Validacion de montos maximos de recarga
interface maxAmountValidation {
  (control: UntypedFormControl): ValidationErrors | null;
}
function maxAmountValidationNumber(maxValue): maxAmountValidation {
  return (control: UntypedFormControl): ValidationErrors | null => {
    var monto = control.value;
    monto = (monto != null) ? Number(monto.replace(/,/g, '')) : null;
    if (monto <= maxValue) {
      return null;
    } else {
      return {
        maxAmount: {
          parsedDomain: monto
        }
      }
    }
  }
}